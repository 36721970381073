export const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const getImageAspectRatio = (imageData) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageData;
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const dimensions = {
        width: img.width,
        height: img.height,
        aspectRatio: aspectRatio,
      };
      resolve(dimensions);
    };
    img.onerror = () => {
      reject(new Error("Failed to load image"));
    };
  });
};

export const createAndSaveImageInParts = async (
  stageRef,
  eventName,
  navigate
) => {
  const stage = stageRef.current;
  const partSize = stage.width() / 2;
  const parts = [];
  const pixelRatio = 3;
  const quality = 1;

  for (let i = 0; i < 2; i++) {
    for (let j = 0; j < 2; j++) {
      stage.toDataURL({
        x: i * partSize,
        y: j * partSize,
        width: partSize,
        height: partSize,
        pixelRatio,
        quality,
        callback: (dataUrl) => {
          parts.push(dataUrl);
        },
      });
    }
  }

  localStorage.setItem("imageParts", JSON.stringify(parts));

  navigate(`/${eventName}/result`, {
    state: {
      imageParts: parts,
    },
  });
};

export const cropExcessBackground = (stage) => {
  console.info("in cropExcessBackground");
  const canvas = stage.toCanvas();
  const context = canvas.getContext("2d");
  const { width, height } = canvas;
  console.log(`width: ${width}, height: ${height}`);
  const imageData = context.getImageData(0, 0, width, height);

  let top = height,
    bottom = 0,
    left = width,
    right = 0;

  console.info(
    `top: ${top}, bottom: ${bottom}, left: ${left}, right: ${right}`
  );

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const index = (y * width + x) * 4;
      // const red = imageData.data[index];
      // const green = imageData.data[index + 1];
      // const blue = imageData.data[index + 2];
      const alpha = imageData.data[index + 3];
      if (alpha > 0) {
        if (x < left) left = x;
        if (x > right) right = x;
        if (y < top) top = y;
        if (y > bottom) bottom = y;
      }
    }
  }

  console.info(
    `top: ${top}, bottom: ${bottom}, left: ${left}, right: ${right}`
  );
  const croppedCanvas = document.createElement("canvas");
  const croppedContext = croppedCanvas.getContext("2d");
  croppedCanvas.width = right - left;
  croppedCanvas.height = bottom - top;
  console.log(
    `croppedCanvas.width: ${croppedCanvas.width}, croppedCanvas.height: ${croppedCanvas.height}`
  );
  croppedContext.drawImage(
    canvas,
    left,
    top,
    croppedCanvas.width,
    croppedCanvas.height,
    0,
    0,
    croppedCanvas.width,
    croppedCanvas.height
  );

  return croppedCanvas;
};
