/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { Stage, Layer } from "react-konva";
import { useNavigate } from "react-router-dom";
import DraggableImage from "./components/DraggableImage";
import { Controls } from "./components/Controls";
import "./SwingieEditYourCharacter.css";
import { useLoading } from "../../../../Components/Common/LoadingSpinner/LoadingContext";
import { showAlert } from "../../../../generalUtils";
import { useLoadImages } from "./hooks/useLoadImages";
import { useImageHandlers } from "./hooks/useImageHandlers";
import { useAppContext } from "../../../../AppContext";
import { ToastContainer } from "react-toastify";
import { handleSaveAndFinish } from "./SaveAndLoad";
import moveTheHeadGif from "../../../../assets/gifs/move_the_heads_432_milk.gif";

const SwingieEditYourCharacterPage = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const stageRef = useRef(null);
  const { eventName } = useAppContext();
  const { showLoading, hideLoading } = useLoading();

  const [activeImage, setActiveImage] = useState(null);
  const [showGif, setShowGif] = useState(false);

  // Load images using a custom hook
  const { images, setImages, canvasWidth, isLoadingComplete } = useLoadImages(
    serverUrl,
    stageRef,
    showLoading,
    hideLoading,
    navigate,
    showAlert
  );

  // Extract selected faces and bodies from images state
  const selectedFaces = images
    .filter((img) => img.draggable)
    .map((img) => img.originalSrc);
  const selectedBodies = images
    .filter((img) => !img.draggable)
    .map((img) => img.originalSrc);

  // Image manipulation handlers
  const { handleResize, handleFlip, handleUpdatePosition, handleZIndexChange } =
    useImageHandlers(
      activeImage,
      setActiveImage,
      stageRef,
      images,
      setImages,
      selectedFaces,
      selectedBodies,
      serverUrl
    );

  // Check if all draggable heads have been moved
  const allHeadsMoved = images
    .filter((img) => img.draggable)
    .every((img) => img.hasMoved);

  const anyHeadMoved = images
    .filter((img) => img.draggable)
    .some((img) => img.hasMoved);

  useEffect(() => {
    if (!anyHeadMoved) {
      const timer = setTimeout(() => {
        setShowGif(true);
      }, 6000);
      return () => clearTimeout(timer);
    } else {
      setShowGif(false);
    }
  }, [anyHeadMoved]);

  // Footer button actions
  const footerButtons = async (name) => {
    showLoading();
    const encodedEventName = encodeURIComponent(eventName);
    if (name === "back") {
      navigate(`/${encodedEventName}/body-gallery`);
    } else if (name === "finish") {
      await handleSaveAndFinish(stageRef, setActiveImage, navigate, eventName);
    }
  };

  const isSelectedImageBody = () => {
    if (!activeImage) return false;
    const selectedImage = images.find((img) => img.src === activeImage);
    return selectedImage && !selectedImage.draggable;
  };

  return (
    <div className="swingie-edityourcharacter-container">
      <ToastContainer />
      <header className="swingie-edityourcharacter-header rtl">
        התאימו ראשים לתוצאה מושלמת
      </header>
      <div className="swingie-edityourcharacter-controls-container">
        <Controls
          handleResize={handleResize}
          handleFlip={handleFlip}
          handleZIndexChange={handleZIndexChange}
          activeImage={activeImage}
          isBodySelected={isSelectedImageBody()}
        />
      </div>
      <div className="swingie-edityourcharacter-canvas-wrapper">
        <div
          className="swingie-edityourcharacter-canvas-container"
          style={{ width: canvasWidth, height: window.innerWidth }}
        >
          <Stage ref={stageRef} width={canvasWidth} height={window.innerWidth}>
            <Layer>
              {images.map((image, index) => (
                <DraggableImage
                  key={index}
                  src={image.src}
                  width={image.width}
                  height={image.height}
                  initialPosition={image.position}
                  canvasWidth={canvasWidth + 10}
                  canvasHeight={window.innerWidth}
                  isSelected={activeImage === image.src}
                  onSelect={() => setActiveImage(image.src)}
                  onUpdatePosition={(src, newPosition, hasMoved) =>
                    handleUpdatePosition(src, newPosition, hasMoved)
                  }
                  draggable={image.draggable}
                />
              ))}
            </Layer>
          </Stage>
        </div>
      </div>

      {showGif && (
        <div className="swingie-edityourcharacter-gif-container">
          <img
            src={moveTheHeadGif}
            alt="Guide GIF"
            className="swingie-edityourcharacter-guide-gif"
          />
        </div>
      )}

      <footer className="swingie-edityourcharacter-footer">
        <button
          className="swingie-edityourcharacter-footer-button"
          onClick={() => footerButtons("back")}
        >
          חזרה
        </button>
        <button
          className="swingie-edityourcharacter-footer-button"
          onClick={() => footerButtons("finish")}
          disabled={!isLoadingComplete || !allHeadsMoved}
        >
          סיום
        </button>
      </footer>
    </div>
  );
};

export default SwingieEditYourCharacterPage;
