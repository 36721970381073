import React, { useEffect, useRef } from "react";
import QRCodeStyling from "qr-code-styling";

const QRCodePopup = ({ data, image, eventName }) => {
  const qrRef = useRef(null);
  const qrCode = useRef(null);

  useEffect(() => {
    // נקה את האלמנט הקיים לפני יצירת הקוד החדש
    if (qrRef.current) {
      qrRef.current.innerHTML = "";
    }

    qrCode.current = new QRCodeStyling({
      width: 2400,
      height: 2400,
      data: data,
      image: image,
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 0, // הקטנת מרווחים כדי להבליט את הלוגו
        imageSize: 0.35, // הגדלת הלוגו ל-35% מגודל הברקוד (במקום 5%)
        hideBackgroundDots: true, // הסתרת נקודות מאחורי הלוגו לנראות נקייה
      },
      dotsOptions: {
        color: "#000000",
        type: "rounded",
      },
      cornersSquareOptions: {
        type: "extra-rounded",
      },
      backgroundOptions: {
        color: "#ffffff",
      },
    });

    qrCode.current.append(qrRef.current);
  }, [data, image]);

  const downloadQRCode = () => {
    qrCode.current.download({
      name: eventName + " - QrCode",
      extension: "png",
    });
  };

  return (
    <div className="qr-popup">
      <div className="qr-preview" ref={qrRef}></div>
      <button onClick={downloadQRCode} className="qr-download-btn">
        הורד
      </button>
    </div>
  );
};

export default QRCodePopup;
