import React from "react";
import { useLoading } from "../../../../Components/Common/LoadingSpinner/LoadingContext";
import "./styles.css";

const RedisInfoSection = ({
  usedMemory,
  maxMemory,
  selectedOption,
  setSelectedOption,
  redisAvailable,
  redisStatus,
  pricing,
  onPlanUpdate,
  serverUrl,
}) => {
  const { showLoading, hideLoading } = useLoading();
  const memoryPercentage = (usedMemory / maxMemory) * 100;

  // ---------------------------------------------------------------------
  //               Changing Redis Plan (dropdown) - upgrade/downgrade
  // ---------------------------------------------------------------------
  const handleDropdownChange = async (event) => {
    const selectedPlan = event.target.value;
    const userConfirmed = window.confirm(
      `Are you sure you want to change to the ${selectedPlan} plan?`
    );

    if (userConfirmed) {
      try {
        const response = await fetch(`${serverUrl}/api/update-redis-plan`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ plan_name: selectedPlan }),
        });

        if (response.ok) {
          setSelectedOption(selectedPlan);
          event.target.value = selectedPlan;
          showLoading();
          await onPlanUpdate();
          hideLoading();
        } else {
          const errorText = await response.text();
          let errorMessage = "An error occurred while updating the plan.";
          try {
            const errorJson = JSON.parse(errorText);
            if (errorJson.error && errorJson.error.message) {
              errorMessage = errorJson.error.message;
            }
          } catch (parseError) {
            console.error("Failed to parse error response:", parseError);
          }
          console.error(
            "Failed to update plan:",
            response.statusText,
            errorText
          );
          alert(`Failed to change Redis plan: ${errorMessage}`);
        }
      } catch (error) {
        console.error("Error updating plan:", error);
        alert(
          `Error updating plan: ${error.message || "Unknown error occurred"}`
        );
      }
    } else {
      event.target.value = selectedOption;
    }
  };

  return (
    <div className="redis-info">
      <div className="redis-header">
        <div className="redis-title">Redis Memory</div>
        <div className="redis-pricing">
          <div className="hourly-rate">
            {pricing?.redis?.hourly || "~$0.000/hour"}
          </div>
          <div className="monthly-rate">
            {pricing?.redis?.monthly || "Max of $0.00/month"}
          </div>
        </div>
      </div>
      <div className="redis-memory-bar">
        <div className="memory-usage">
          <span className="memory-numbers">
            {usedMemory.toFixed(1)}/{maxMemory}MB
          </span>
          <div className="progress-bar">
            <div
              className="progress-fill"
              style={{ width: `${Math.min(memoryPercentage, 100)}%` }}
            ></div>
          </div>
        </div>

        <div className="redis-controls">
          <select
            onChange={handleDropdownChange}
            value={selectedOption}
            disabled={!redisAvailable}
            className="plan-select"
          >
            <option value="mini">mini (25MB)</option>
            <option value="premium-0">premium-0 (50MB)</option>
            <option value="premium-1">premium-1 (100MB)</option>
            <option value="premium-2">premium-2 (250MB)</option>
            <option value="premium-3">premium-3 (500MB)</option>
            <option value="premium-5">premium-5 (1GB)</option>
          </select>
          <div
            className={`redis-status ${
              redisAvailable ? "available" : "processing"
            }`}
          >
            {redisStatus}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedisInfoSection;
