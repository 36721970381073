// BodyUtils.js

export const reorderImages = (images, number) => {
  let preferredOrder;
  switch (number) {
    case 3:
      preferredOrder = [3, 2, 1];
      break;
    case 2:
      preferredOrder = [2, 1, 3];
      break;
    case 1:
      preferredOrder = [1, 2, 3];
      break;
    default:
      preferredOrder = [1, 2, 3];
  }

  return [...images].sort((a, b) => {
    const aVal = parseInt(a.path.split("-").pop().split(".")[0][0], 10);
    const bVal = parseInt(b.path.split("-").pop().split(".")[0][0], 10);
    return preferredOrder.indexOf(aVal) - preferredOrder.indexOf(bVal);
  });
};
