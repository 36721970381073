import React, { useState, useEffect } from "react";
import "./styles.css";

const ResizeImages = ({ serverUrl }) => {
  const [targetHeight, setTargetHeight] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [resizeError, setResizeError] = useState(null);

  function handleHeightChange(e) {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setTargetHeight(value);
    }
  }

  const handleHeightBlur = () => {
    const heightValue = parseInt(targetHeight);
    if (heightValue < 200 || heightValue > 4000) {
      alert("Please enter a valid height between 200 and 4000.");
      setTargetHeight("");
    }
  };

  const handleResizeImages = () => {
    if (!targetHeight) {
      alert("Please enter a valid height between 200 and 4000.");
      return;
    }

    setIsButtonDisabled(true);
    setTargetHeight("");
    setResizeError(null);

    setTimeout(() => setIsButtonDisabled(false), 10000);

    fetch(`${serverUrl}/api/resize-bodies-images`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ target_height: parseInt(targetHeight) }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.text().then((errorText) => {
            if (response.status === 404) {
              setResizeError(
                "The folder for resizing images was not found on the server."
              );
            } else if (response.status === 400) {
              setResizeError(
                "Invalid request. Please ensure the height is within the range."
              );
            } else {
              setResizeError(`Error resizing images: ${errorText}`);
            }
          });
        }
        alert("Image resize request has been started successfully.");
      })
      .catch((error) => {
        setResizeError(
          `Error resizing images: ${error.message || "Unknown error occurred"}`
        );
      });
  };

  useEffect(() => {
    if (resizeError) {
      alert(resizeError);
      console.error(resizeError);
    }
  }, [resizeError]);

  return (
    <div className="resize-images-section">
      <h2 className="section-header">Resize Body Images</h2>
      <div className="resize-images-panel">
        <div className="resize-input-button-container">
          <input
            type="number"
            placeholder="Enter height"
            value={targetHeight}
            onChange={handleHeightChange}
            onBlur={handleHeightBlur}
            className="resize-input"
          />
          <button
            onClick={handleResizeImages}
            className="resize-button"
            disabled={isButtonDisabled}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResizeImages;
