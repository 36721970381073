import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLoading } from "../../../../Components/Common/LoadingSpinner/LoadingContext";
import "./styles.css";

const CreateEventForm = ({ serverUrl, onEventCreated }) => {
  const { showLoading, hideLoading } = useLoading();
  const [newEvent, setNewEvent] = useState({
    eventName: "",
    startTime: null,
    endTime: null,
    reusableHeads: false,
    keepHeads: false,
    eventType: "",
    stationCount: 1,
  });

  const handleCreateEvent = async (e) => {
    e.preventDefault();
    if (new Date(newEvent.startTime) >= new Date(newEvent.endTime)) {
      return;
    }
    showLoading();
    try {
      const startTimeFormatted = newEvent.startTime
        .toLocaleString("he-IL", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })
        .replace(",", "");

      const endTimeFormatted = newEvent.endTime
        .toLocaleString("he-IL", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })
        .replace(",", "");

      const response = await fetch(`${serverUrl}/create-event`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          event_name: newEvent.eventName,
          start_time: startTimeFormatted,
          end_time: endTimeFormatted,
          reusable_heads: newEvent.reusableHeads,
          keep_heads: newEvent.keepHeads,
          event_type: newEvent.eventType,
          domain: window.location.origin,
          station_count: newEvent.stationCount,
        }),
      });

      if (response.ok) {
        const createdEvent = await response.json();
        setNewEvent({
          eventName: "",
          startTime: null,
          endTime: null,
          reusableHeads: false,
          keepHeads: false,
          eventType: "",
        });
        onEventCreated(createdEvent);
      } else {
        hideLoading();
        console.error("Error creating event:", response.statusText);
      }
    } catch (error) {
      hideLoading();
      console.error("Error creating event:", error);
    }
    hideLoading();
  };

  const toggleReusableHeads = () => {
    setNewEvent((prevEvent) => ({
      ...prevEvent,
      reusableHeads: !prevEvent.reusableHeads,
    }));
  };

  const toggleKeepHeads = () => {
    setNewEvent((prevEvent) => ({
      ...prevEvent,
      keepHeads: !prevEvent.keepHeads,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "eventName") {
      const filteredValue = value.replace(/[^a-zA-Z0-9&]/g, "");
      setNewEvent((prevEvent) => ({
        ...prevEvent,
        [name]: filteredValue,
      }));
    } else {
      setNewEvent((prevEvent) => ({
        ...prevEvent,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date, field) => {
    if (field === "startTime" && newEvent.endTime && date >= newEvent.endTime) {
      setNewEvent((prevEvent) => ({
        ...prevEvent,
        [field]: date,
        endTime: null,
      }));
    } else {
      setNewEvent((prevEvent) => ({
        ...prevEvent,
        [field]: date,
      }));
    }
  };

  return (
    <div className="create-event-section">
      <h2 className="section-header">Create New Event</h2>
      <div className="create-event-panel">
        <form onSubmit={handleCreateEvent}>
          <input
            type="text"
            name="eventName"
            placeholder="Event Name"
            className="input-centered"
            value={newEvent.eventName}
            onChange={handleInputChange}
            required
          />
          <div className="date-picker-row">
            <DatePicker
              selected={newEvent.startTime}
              onChange={(date) => handleDateChange(date, "startTime")}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="dd/MM/yyyy HH:mm"
              placeholderText="Start of the event"
              className="date-picker-input"
              popperPlacement="bottom-end"
            />

            <DatePicker
              selected={newEvent.endTime}
              onChange={(date) => handleDateChange(date, "endTime")}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="dd/MM/yyyy HH:mm"
              placeholderText="End of the event"
              className="date-picker-input"
              minDate={newEvent.startTime}
              disabled={!newEvent.startTime}
              popperPlacement="bottom-start"
            />
          </div>
          <select
            name="eventType"
            value={newEvent.eventType}
            onChange={handleInputChange}
            className="event-type-select"
            required
          >
            <option value="" disabled>
              Select Event Type
            </option>
            <option value="swingies">Swingies</option>
            <option value="minimove">MiniMove</option>
          </select>

          {/* 2x4 Grid layout for toggles and station count - using classes instead of inline styles */}
          <div className="toggles-station-container">
            {/* Row 1, Column 1: First toggle label */}
            <div
              className="toggle-label-text grid-row-1 grid-col-1"
              onClick={toggleReusableHeads}
            >
              Reuseable Faces
            </div>

            {/* Row 1, Column 2: First toggle button */}
            <div className="toggle-switch grid-row-1 grid-col-2">
              <label>
                <input
                  type="checkbox"
                  checked={newEvent.reusableHeads}
                  onChange={toggleReusableHeads}
                />
                <span className="slider round"></span>
              </label>
            </div>

            {/* Row 1, Column 3: Station count label */}
            <div className="station-label grid-row-1 grid-col-3">
              Station Count
            </div>

            {/* Row 1, Column 4: Station count dropdown */}
            <select
              name="stationCount"
              value={newEvent.stationCount || 1}
              onChange={(e) =>
                setNewEvent((prev) => ({
                  ...prev,
                  stationCount: e.target.value,
                }))
              }
              className="event-type-select station-select grid-row-1 grid-col-4"
            >
              <option value="0.35">0.35</option>
              <option value="0.7">0.7</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
            </select>

            {/* Row 2, Column 1: Second toggle label */}
            <div
              className="toggle-label-text grid-row-2 grid-col-1"
              onClick={toggleKeepHeads}
            >
              Keep Faces
            </div>

            {/* Row 2, Column 2: Second toggle button */}
            <div className="toggle-switch grid-row-2 grid-col-2">
              <label>
                <input
                  type="checkbox"
                  checked={newEvent.keepHeads}
                  onChange={toggleKeepHeads}
                />
                <span className="slider round"></span>
              </label>
            </div>

            {/* Row 2, Column 3 and 4 are empty */}
          </div>

          <button type="submit" className="create-event-button">
            Create Event
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateEventForm;
