import { toast } from "react-toastify";

export const showAlert = (state, msg, timeout, delay = 0) => {
  const message = msg;
  if (state === "warn") {
    toast.warning(message, { autoClose: timeout });
  } else if (state === "err") {
    toast.error(message, { autoClose: timeout });
  }
  setTimeout(() => {
    // Just waiting, no further action performed
  }, delay);
};

// Generic fetch function
export const fetchData = async (
  serverUrl,
  endpoint,
  eventName,
  method = "GET",
  bodyData = null,
  retries = 3,
  initialTimeout = 10000
) => {
  const url = `${serverUrl}${endpoint}?event_name=${eventName}`;
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (method === "POST" && bodyData) {
    options.body = JSON.stringify(bodyData);
  }

  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(
        () => controller.abort(),
        initialTimeout * attempt
      );

      const response = await fetch(url, {
        ...options,
        signal: controller.signal,
      });
      clearTimeout(timeoutId);

      if (!response.status.toString().startsWith("2")) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      if (attempt === retries) {
        console.error(
          `Error fetching ${endpoint} after ${retries} attempts:`,
          error
        );
        throw error;
      } else if (error.name === "AbortError") {
        console.warn(
          `Timeout on attempt ${attempt} for ${endpoint}. Retrying...`
        );
      } else {
        console.error(`Error on attempt ${attempt} for ${endpoint}:`, error);
      }
    }
  }
};
