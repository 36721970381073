/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./SwingieFaceGallery.css";
import { useLoading } from "../../../../Components/Common/LoadingSpinner/LoadingContext";
import { useAppContext } from "../../../../AppContext";
import { ToastContainer } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  getImageBlobFromDB,
  updateLastUpdateTime,
  getLastUpdateTime,
  saveFaceImageToDB,
  getAllKeysFromDB,
  clearStore,
  deleteSelectedImages,
  FACE_STORE_NAME,
} from "../../../../IndexedDBUtils";
import { useGif } from "../../../../GifContext";
import gifFaceFailed from "../../../../assets/gifs/result-face_failed.gif";
import gifFailed from "../../../../assets/gifs/result-failed.gif";

// Configuration constants
const FACE_REFRESH_INTERVAL_SECONDS = 6; // Polling interval for image updates (seconds)
const SESSION_TIMEOUT_SECONDS = FACE_REFRESH_INTERVAL_SECONDS * 15; // Inactivity timeout (15 polling intervals)

const SwingieFaceGalleryPage = () => {
  const { showGif } = useGif();
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { showLoading, hideLoading } = useLoading();
  const { eventName } = useAppContext();
  const navigate = useNavigate();

  // Component state
  const [imagePaths, setImagePaths] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const number = parseInt(sessionStorage.getItem("number")) || 1; // Number of faces to select

  // Activity timer state
  const [timeLeft, setTimeLeft] = useState(SESSION_TIMEOUT_SECONDS);

  // Refs to manage intervals and component state
  const timerIntervalRef = useRef(null);
  const pollingIntervalRef = useRef(null);
  const isActiveRef = useRef(true);

  // Cleanup intervals on unmount
  useEffect(() => {
    return () => {
      if (timerIntervalRef.current) clearInterval(timerIntervalRef.current);
      if (pollingIntervalRef.current) clearInterval(pollingIntervalRef.current);
    };
  }, []);

  // Manage page visibility: pause/resume timers when page is hidden/visible
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        isActiveRef.current = false;
        console.log("Page hidden, pausing timers");
        if (timerIntervalRef.current) clearInterval(timerIntervalRef.current);
        if (pollingIntervalRef.current)
          clearInterval(pollingIntervalRef.current);
      } else {
        isActiveRef.current = true;
        console.log("Page visible, resuming timers");
        startActivityTimer();
        startPollingInterval();
        loadImages();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // Initial setup effect
  useEffect(() => {
    // Clean up previously stored full face images
    const cleanupPreviousImages = () => {
      const faceUrls = JSON.parse(
        sessionStorage.getItem("faceImageBlobUrls") || "{}"
      );
      Object.values(faceUrls).forEach((url) => {
        if (url) URL.revokeObjectURL(url);
      });
      sessionStorage.removeItem("faceImageBlobUrls");
      sessionStorage.removeItem("faceImagesDownloadStatus");
    };

    cleanupPreviousImages();
    startActivityTimer();
    loadImages();
    startPollingInterval();
  }, []);

  // Start the inactivity timer
  const startActivityTimer = () => {
    if (timerIntervalRef.current) clearInterval(timerIntervalRef.current);
    timerIntervalRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerIntervalRef.current);
          window.location.reload();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  // Start the polling timer to refresh images from server or DB
  const startPollingInterval = () => {
    if (pollingIntervalRef.current) clearInterval(pollingIntervalRef.current);
    pollingIntervalRef.current = setInterval(() => {
      loadImages();
    }, FACE_REFRESH_INTERVAL_SECONDS * 1000);
  };

  // Reset activity timer on user interaction
  const resetActivityTimer = () => {
    setTimeLeft(SESSION_TIMEOUT_SECONDS);
  };

  // Format remaining time as M:SS
  const formatTimeLeft = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Load images from IndexedDB or the server based on last update time
  const loadImages = async () => {
    if (!isActiveRef.current) return;
    const lastUpdateTime = await getLastUpdateTime(FACE_STORE_NAME);
    const currentTime = new Date();
    const timeDifference = (currentTime - new Date(lastUpdateTime)) / 1000;

    if (timeDifference < FACE_REFRESH_INTERVAL_SECONDS) {
      await loadImagesFromDB();
    } else {
      console.log("Checking for updates from server...");
      await fetchDataFromServer();
    }
  };

  // Load images from IndexedDB
  const loadImagesFromDB = async () => {
    const dbImages = [];
    const allKeys = await getAllKeysFromDB(FACE_STORE_NAME);
    for (const path of allKeys) {
      const imageBlob = await getImageBlobFromDB(FACE_STORE_NAME, path);
      if (imageBlob) {
        const imageUrl = URL.createObjectURL(imageBlob);
        dbImages.push({ path, image: imageUrl });
      }
    }
    setImagePaths(dbImages);
    if (dbImages.length === 0) {
      console.log("No images found in IndexedDB, fetching from server...");
      fetchDataFromServer();
    }
    hideLoading();
  };

  // Fetch images from server and update IndexedDB accordingly
  const fetchDataFromServer = async () => {
    if (!eventName) {
      console.error("FaceGallery - eventName is missing.");
      return;
    }
    const encodedEventName = encodeURIComponent(eventName);
    try {
      const response = await fetch(
        `${serverUrl}/api/faces?event_name=${encodedEventName}`
      );
      console.log("Response from server:", response);
      console.log("Response status:", response.status);
      if (response.status === 400) {
        console.error("Bad request received. Stopping further requests.");
        hideLoading();
        return;
      }
      if (response.status === 204) {
        console.warn("No images found on server. Clearing local storage.");
        await clearStore(FACE_STORE_NAME);
        setImagePaths([]);
        setSelectedImages([]);
        console.log("Cleared IndexedDB.");
        hideLoading();
        return;
      }
      const data = await response.json();
      if (!Array.isArray(data) || data.length === 0) {
        console.warn("Server returned an empty list. Clearing local data.");
        await clearStore(FACE_STORE_NAME);
        setImagePaths([]);
        setSelectedImages([]);
        hideLoading();
        return;
      }
      const keysFromDB = await getAllKeysFromDB(FACE_STORE_NAME);
      const newPaths = data.filter((path) => !keysFromDB.includes(path));
      const pathsToDelete = keysFromDB.filter((path) => !data.includes(path));
      if (newPaths.length === 0 && pathsToDelete.length === 0) {
        console.log("No changes in paths, skipping server fetch.");
        await loadImagesFromDB();
        hideLoading();
        updateLastUpdateTime(FACE_STORE_NAME);
        return;
      }
      await deleteSelectedImages(FACE_STORE_NAME, pathsToDelete);
      const imagePromises = newPaths.map(async (path) => {
        const response = await fetch(
          `${serverUrl}/image${path}?size=thumbnail`
        );
        if (!response.ok) {
          console.error(`Failed to fetch image for path: ${path}`);
          return;
        }
        const blob = await response.blob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
          const base64Data = reader.result;
          await saveFaceImageToDB(path, base64Data);
          console.log(`Image saved to IndexedDB: ${path}`);
        };
      });
      await Promise.all(imagePromises);
      setImagePaths(
        data.map((path) => ({
          path,
          image: `${serverUrl}/image${path}?size=thumbnail`,
        }))
      );
      const updatedSelectedImages = selectedImages.filter((img) =>
        data.includes(img)
      );
      setSelectedImages(updatedSelectedImages);
    } catch (error) {
      console.error("Error fetching faces:", error);
    } finally {
      hideLoading();
    }
  };

  // Toggle image selection
  const toggleImageSelection = (imagePath) => {
    resetActivityTimer();
    if (selectedImages.includes(imagePath)) {
      setSelectedImages(selectedImages.filter((img) => img !== imagePath));
    } else {
      if (selectedImages.length < number) {
        setSelectedImages([...selectedImages, imagePath]);
      }
    }
  };

  // Navigate to the body gallery page and initiate background downloads
  const goToBodyGallery = () => {
    showLoading();
    try {
      sessionStorage.setItem("selectedFaces", JSON.stringify(selectedImages));
      sessionStorage.setItem("faceImageBlobUrls", JSON.stringify({}));
      sessionStorage.setItem(
        "faceImagesDownloadStatus",
        JSON.stringify({
          total: selectedImages.length,
          completed: 0,
          failed: 0,
          inProgress: true,
        })
      );
      const downloadPromises = selectedImages.map(async (path) => {
        try {
          console.log(`Starting background download for ${path}`);
          const response = await fetch(`${serverUrl}/image${path}?size=full`);
          if (!response.ok) {
            const statusCode = response.status;
            console.error(`Failed to get full image: ${path}`);
            const error = new Error(`Failed to fetch ${path}`);
            error.statusCode = statusCode;
            throw error;
          }
          const imageBlob = await response.blob();
          const blobUrl = URL.createObjectURL(imageBlob);
          console.log(`Created blob URL for ${path}: ${blobUrl}`);
          const currentUrls = JSON.parse(
            sessionStorage.getItem("faceImageBlobUrls") || "{}"
          );
          currentUrls[path] = blobUrl;
          sessionStorage.setItem(
            "faceImageBlobUrls",
            JSON.stringify(currentUrls)
          );
          const currentStatus = JSON.parse(
            sessionStorage.getItem("faceImagesDownloadStatus") || "{}"
          );
          currentStatus.completed += 1;
          sessionStorage.setItem(
            "faceImagesDownloadStatus",
            JSON.stringify(currentStatus)
          );
          return { success: true, path };
        } catch (error) {
          console.error(`Error downloading image: ${path}`, error);
          const currentStatus = JSON.parse(
            sessionStorage.getItem("faceImagesDownloadStatus") || "{}"
          );
          currentStatus.failed += 1;
          sessionStorage.setItem(
            "faceImagesDownloadStatus",
            JSON.stringify(currentStatus)
          );
          return {
            success: false,
            path,
            error: error.message,
            statusCode: error.statusCode || 0,
          };
        }
      });
      Promise.all(downloadPromises)
        .then((results) => {
          const failures = results.filter((result) => !result.success);
          const finalStatus = JSON.parse(
            sessionStorage.getItem("faceImagesDownloadStatus") || "{}"
          );
          finalStatus.inProgress = false;
          sessionStorage.setItem(
            "faceImagesDownloadStatus",
            JSON.stringify(finalStatus)
          );
          if (failures.length > 0) {
            console.error(`${failures.length} downloads failed:`, failures);
            const notFoundError = failures.find((f) => f.statusCode === 404);
            const gifToShow = notFoundError ? gifFaceFailed : gifFailed;
            const gifDuration = notFoundError ? 17500 : 30000;
            localStorage.setItem(
              "pendingGif",
              JSON.stringify({
                gifUrl: gifToShow,
                navigateTo: `/${eventName}`,
                duration: gifDuration,
                timestamp: Date.now(),
              })
            );
          } else {
            console.log("All background downloads completed successfully");
          }
        })
        .catch((error) => {
          console.error("Error in background downloads:", error);
          const finalStatus = JSON.parse(
            sessionStorage.getItem("faceImagesDownloadStatus") || "{}"
          );
          finalStatus.inProgress = false;
          finalStatus.error = error.message;
          sessionStorage.setItem(
            "faceImagesDownloadStatus",
            JSON.stringify(finalStatus)
          );
          localStorage.setItem(
            "pendingGif",
            JSON.stringify({
              gifUrl: gifFailed,
              navigateTo: `/${eventName}`,
              duration: 30000,
              timestamp: Date.now(),
            })
          );
        });
      navigate(`/${eventName}/body-gallery`);
    } catch (error) {
      console.error("Error in goToBodyGallery:", error);
      showGif(gifFailed, `/${eventName}`, 30000);
    } finally {
      hideLoading();
    }
  };

  // Navigate back to the home screen
  const goBack = async () => {
    showLoading();
    navigate(`/${eventName}`);
  };

  return (
    <div className="swingie-facegallery-container" onClick={resetActivityTimer}>
      <ToastContainer />
      <div className="swingie-facegallery-header">
        <div className="swingie-facegallery-timer">
          <span className="swingie-facegallery-timer-text">
            הזמן שנותר לבחירה:
          </span>
          <span
            className={`swingie-facegallery-timer-clock ${
              timeLeft < 30 ? "swingie-facegallery-timer-clock-low" : ""
            }`}
          >
            {formatTimeLeft()}
          </span>
        </div>
        <h1 className="swingie-facegallery-title rtl">בחרו את הראשים שלכם</h1>
      </div>
      <div className="swingie-facegallery-grid">
        {imagePaths.map(({ path, image }, index) => {
          const isSelected = selectedImages.includes(path);
          const isSelectable = selectedImages.length < number || isSelected;
          return (
            <div
              key={index}
              className={`swingie-facegallery-image-container ${
                isSelected ? "swingie-facegallery-image-selected" : ""
              } ${
                !isSelectable ? "swingie-facegallery-image-unselectable" : ""
              }`}
              onClick={() => isSelectable && toggleImageSelection(path)}
            >
              <LazyLoadImage
                src={image}
                alt={`Face ${index}`}
                className="swingie-facegallery-image"
                effect="blur"
              />
              {isSelected && (
                <div className="swingie-facegallery-image-overlay">
                  <span className="text-white text-2xl">✔</span>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="swingie-facegallery-button-container">
        <button className="swingie-facegallery-button rtl" onClick={goBack}>
          חזרה
        </button>
        <button
          className={`swingie-facegallery-button rtl ${
            selectedImages.length === number
              ? ""
              : "swingie-facegallery-button-disabled"
          }`}
          onClick={goToBodyGallery}
          disabled={selectedImages.length !== number}
        >
          <span>הבא </span>
          <span style={{ fontSize: "18px", fontWeight: "normal" }}>
            ({selectedImages.length}/{number})
          </span>
        </button>
      </div>
    </div>
  );
};

export default SwingieFaceGalleryPage;
