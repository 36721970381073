import React from "react";
import "./styles.css";

const BillingHeader = ({ billingInfo, pricing }) => {
  if (!billingInfo.total) {
    return (
      <div className="billing-info">
        <p className="billing-text">Loading billing information...</p>
      </div>
    );
  }

  return (
    <div className="billing-header">
      <div className="billing-row">Current Month Bill: {billingInfo.total}</div>
      <div className="billing-row">
        Current Rate:{" "}
        {pricing?.total?.hourly?.replace("~$", "")?.replace("/hour", "") ||
          "0.000"}
        /hour
      </div>
    </div>
  );
};

export default BillingHeader;
