// BodyImageGrid.js - Grid component for displaying selectable body images in Swingies app
import React from "react";
import { reorderImages } from "./BodyUtils";
import "./SwingieBodyGallery";

const ImageGrid = ({
  images,
  selectedImages,
  toggleImageSelection,
  totalValue,
  number,
  serverUrl,
}) => {
  const sortedImages = reorderImages(images, number);

  return (
    <div className="swingie-bodygallery-grid">
      {sortedImages.map((imageObj, index) => {
        const { path, url } = imageObj;
        const imageValue = parseInt(path.split("-").pop().split(".")[0][0], 10);
        const isSelected = selectedImages.includes(path);
        const isSelectable = totalValue + imageValue <= number || isSelected;

        return (
          <div
            key={path}
            className="swingie-bodygallery-image-container"
            style={{
              opacity: !isSelectable && !isSelected ? 0.5 : 1,
            }}
            onClick={() => toggleImageSelection(path)}
          >
            <img
              src={url || `${serverUrl}/image${path}?size=thumbnail`}
              alt={`Body ${index}`}
              className={`swingie-bodygallery-image ${
                isSelected ? "swingie-bodygallery-image-selected" : ""
              }`}
            />

            {isSelected && (
              <div className="swingie-bodygallery-image-overlay">
                <span>✔</span>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ImageGrid;
