/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./SwingieResult.css";
import { useAppContext } from "../../../../AppContext";
import { useLoading } from "../../../../Components/Common/LoadingSpinner/LoadingContext";
import { ToastContainer } from "react-toastify";
import Modal from "./Modal";
import {
  BODY_STORE_NAME,
  FACE_STORE_NAME,
  deleteSelectedImages,
} from "../../../../IndexedDBUtils";
// Import GIFs locally instead of fetching from the server
import gifSavingResult from "../../../../assets/gifs/result-saving_result.gif";
import gifDone from "../../../../assets/gifs/result-done.gif";
import gifFailed from "../../../../assets/gifs/result-failed.gif";
import gifFaceFailed from "../../../../assets/gifs/result-face_failed.gif";

// Configuration object - can be moved to a separate config file
const CONFIG = {
  ENABLE_DOWNLOAD_FLOW: false, // When true, shows download prompt and enables download functionality
  ENABLE_BACKGROUND_TRIM: true, // האם להפעיל חיתוך רקע אוטומטי
  BACKGROUND_TRIM_THRESHOLD: 240, // סף זיהוי רקע (0-255, 255 הוא לבן מלא)
  TRIM_PADDING: 5, // שוליים בפיקסלים להשאיר מסביב לתוכן בחיתוך
};

// פונקציה לקיצוץ רקע לבן או שקוף מתמונה
const trimImageBackground = (imageUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      // אם התמונה קטנה מדי, לא מבצעים קיצוץ
      if (img.width < 50 || img.height < 50) {
        console.log("התמונה קטנה מדי לקיצוץ");
        resolve(imageUrl);
        return;
      }

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // ציור התמונה המקורית
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      // לבדיקת ביצועים
      console.time("trimImageBackground");

      // סף זיהוי רקע
      const threshold = CONFIG.BACKGROUND_TRIM_THRESHOLD || 240;

      // מציאת גבולות התוכן (לא רקע)
      let minX = canvas.width;
      let minY = canvas.height;
      let maxX = 0;
      let maxY = 0;

      // סריקה מהירה יותר - דגימה של פיקסלים (לא כל פיקסל)
      // סריקה של כל 3 פיקסלים במקום כל פיקסל בודד
      const sampleRate = 3;

      // קבלת נתוני הפיקסלים ושמירה בהיקף מקומי (לא כמשתנה)
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      for (let y = 0; y < canvas.height; y += sampleRate) {
        for (let x = 0; x < canvas.width; x += sampleRate) {
          const index = (y * canvas.width + x) * 4;

          // בדיקה אם הפיקסל אינו לבן או שקוף
          const r = data[index];
          const g = data[index + 1];
          const b = data[index + 2];
          const a = data[index + 3];

          // אם הפיקסל לא שקוף ולא קרוב מאוד ללבן
          if (a > 20 && !(r > threshold && g > threshold && b > threshold)) {
            minX = Math.min(minX, x);
            minY = Math.min(minY, y);
            maxX = Math.max(maxX, x);
            maxY = Math.max(maxY, y);
          }
        }
      }

      console.timeEnd("trimImageBackground");

      // הוספת שוליים
      const padding = CONFIG.TRIM_PADDING || 5;
      minX = Math.max(0, minX - padding);
      minY = Math.max(0, minY - padding);
      maxX = Math.min(canvas.width, maxX + padding);
      maxY = Math.min(canvas.height, maxY + padding);

      // בדיקה אם יש תוכן משמעותי שזוהה
      if (
        maxX <= minX ||
        maxY <= minY ||
        maxX - minX < 20 ||
        maxY - minY < 20 || // מניעת חיתוך תמונות קטנות מדי
        maxX - minX > canvas.width * 0.95 || // אם התוכן תופס רוב התמונה, לא חותכים
        maxY - minY > canvas.height * 0.95
      ) {
        console.log(
          "לא זוהה תוכן משמעותי לקיצוץ או התוכן ממלא רוב התמונה, מחזיר תמונה מקורית"
        );

        // במקום delete imageData - נסמן למנגנון ניקוי הזיכרון שהאובייקט לא בשימוש
        // imageData = null; - אין צורך בזה בגלל היקף משתנים אוטומטי

        resolve(imageUrl);
        return;
      }

      // ניקוי הקנבס המקורי לשחרור זיכרון
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // יצירת קנבס חדש עם הגדלים המקוצצים
      const trimmedCanvas = document.createElement("canvas");
      const trimmedCtx = trimmedCanvas.getContext("2d");

      trimmedCanvas.width = maxX - minX;
      trimmedCanvas.height = maxY - minY;

      // ציור החלק הרצוי בלבד
      trimmedCtx.drawImage(
        img, // משתמשים בתמונה המקורית במקום בקנבס
        minX,
        minY,
        maxX - minX,
        maxY - minY,
        0,
        0,
        maxX - minX,
        maxY - minY
      );

      // המרה חזרה ל-URL
      trimmedCanvas.toBlob((blob) => {
        // ביטול ה-URL הישן לשחרור זיכרון
        if (imageUrl.startsWith("blob:")) {
          URL.revokeObjectURL(imageUrl);
        }

        const trimmedUrl = URL.createObjectURL(blob);
        console.log(`חיתוך בוצע בהצלחה: ${minX},${minY} - ${maxX},${maxY}`);
        resolve(trimmedUrl);
      }, "image/png");
    };

    img.onerror = () => {
      console.error("שגיאה בטעינת התמונה לקיצוץ");
      resolve(imageUrl); // מחזיר את התמונה המקורית במקרה של שגיאה
    };

    img.src = imageUrl;
  });
};

const SwingieResultPage = () => {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const { userId, eventName } = useAppContext();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  // Retrieve final image data from localStorage
  const [finalImageState, setFinalImageState] = useState(
    localStorage.getItem("finalImage")
  );
  const [processedImage, setProcessedImage] = useState(false);

  const [gifUrl, setGifUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Returns the corresponding local GIF URL for the given gifName
  const getGifUrl = (gifName) => {
    switch (gifName) {
      case "saving_result":
        return gifSavingResult;
      case "done":
        return gifDone;
      case "failed":
        return gifFailed;
      case "face_failed":
        return gifFaceFailed;
      default:
        return null;
    }
  };

  // אפקט לחיתוך רקע התמונה בטעינה ראשונית
  useEffect(() => {
    if (finalImageState && !processedImage) {
      // רק אם מאופשר בקונפיגורציה
      if (CONFIG.ENABLE_BACKGROUND_TRIM) {
        showLoading();
        trimImageBackground(finalImageState)
          .then((trimmedImage) => {
            if (trimmedImage !== finalImageState) {
              // מעדכן את התמונה בלוקל סטורג' ובסטייט
              localStorage.setItem("finalImage", trimmedImage);
              setFinalImageState(trimmedImage);
            }
            setProcessedImage(true);
          })
          .catch((error) => {
            console.error("שגיאה בקיצוץ התמונה:", error);
            setProcessedImage(true);
          })
          .finally(() => {
            hideLoading();
          });
      } else {
        setProcessedImage(true);
        hideLoading();
      }
    } else {
      hideLoading();
    }
  }, [finalImageState, processedImage, showLoading, hideLoading]);

  // Handles image approval and submission to the server.
  const handleApprove = async (download = false) => {
    // Show waiting GIF
    setGifUrl(getGifUrl("saving_result"));
    try {
      const selectedFaces =
        JSON.parse(sessionStorage.getItem("selectedFaces")) || [];
      const selectedBodies =
        JSON.parse(sessionStorage.getItem("selectedBodies")) || [];

      // Get current image from localStorage
      const currentImage = localStorage.getItem("finalImage");

      if (currentImage) {
        let imageToSend = currentImage;

        // Check if image is a blob URL and convert to base64 if needed
        if (currentImage.startsWith("blob:")) {
          try {
            // Fetch the blob data
            const response = await fetch(currentImage);
            const blob = await response.blob();

            // Convert blob to base64 data URL
            imageToSend = await new Promise((resolve) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.readAsDataURL(blob);
            });

            console.log("Blob to base64 conversion completed successfully");
          } catch (error) {
            console.error("Error converting blob URL to base64:", error);
            throw new Error("Image processing error");
          }
        }

        const response = await fetch(
          `${serverUrl}/approve?event_name=${eventName}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              image: imageToSend, // Use the converted image
              selected_faces: selectedFaces,
              selected_bodies: selectedBodies,
              UserId: userId,
              event_name: eventName,
            }),
          }
        );

        const responseData = await response.json();

        if (response.ok && responseData.success) {
          // Processing successful - show success animation
          setGifUrl(getGifUrl("done"));

          // Delete selected images if reFace is not active
          const reFace = sessionStorage.getItem("reFace");
          if (reFace === "false") {
            const selectedBodies =
              JSON.parse(sessionStorage.getItem("selectedBodies")) || [];
            await deleteSelectedImages(BODY_STORE_NAME, selectedBodies);
            await deleteSelectedImages(FACE_STORE_NAME, selectedFaces);
          }

          if (download) {
            await handleDownload();
          }

          // Navigate back after animation completes
          setTimeout(() => {
            // Clean up image from localStorage
            localStorage.removeItem("finalImage");

            // Revoke blob URL to prevent memory leaks
            if (finalImageState && finalImageState.startsWith("blob:")) {
              URL.revokeObjectURL(finalImageState);
            }

            navigate(`/${eventName}`, { state: { imageUrl: null } });
          }, 7000);
        } else {
          // Handle error scenarios based on status code
          if (response.status === 409) {
            // Conflict - some faces are already in use
            setGifUrl(getGifUrl("face_failed"));
            setTimeout(() => {
              navigate(`/${eventName}/face-gallery`, {
                state: {
                  error:
                    "Some faces are already in use, please select different faces",
                  usedFaces: responseData.used_faces,
                },
              });
            }, 10000);
          } else if (response.status === 404) {
            // Not Found - missing faces
            setGifUrl(getGifUrl("failed"));
            setTimeout(() => {
              navigate(`/${eventName}`, {
                state: {
                  error:
                    "Some faces are missing or inaccessible, please try again",
                  missingFaces: responseData.missing_faces,
                },
              });
            }, 15000);
          } else {
            // General error
            setGifUrl(getGifUrl("failed"));
            setTimeout(() => {
              navigate(`/${eventName}`, {
                state: {
                  error: responseData.error || "An unexpected error occurred",
                },
              });
            }, 15000);
          }
        }
      } else {
        setGifUrl(getGifUrl("failed"));
        setTimeout(() => {
          navigate(`/${eventName}`, {
            state: {
              error: "No final image found",
            },
          });
        }, 15000);
      }
    } catch (error) {
      setGifUrl(getGifUrl("failed"));
      setTimeout(() => {
        navigate(`/${eventName}`, {
          state: {
            error: "Connection error. Please try again.",
          },
        });
      }, 15000);
    } finally {
      try {
        // Release blob URLs from sessionStorage
        const faceUrls = JSON.parse(
          sessionStorage.getItem("faceImageBlobUrls") || "{}"
        );
        Object.values(faceUrls).forEach((url) => {
          if (url) URL.revokeObjectURL(url);
        });
        const bodyUrls = JSON.parse(
          sessionStorage.getItem("bodyImageBlobUrls") || "{}"
        );
        Object.values(bodyUrls).forEach((url) => {
          if (url) URL.revokeObjectURL(url);
        });
        console.log("Released all blob URLs after processing");
      } catch (error) {
        console.error("Error releasing blob URLs:", error);
      }
      hideLoading();
    }
  };

  // Navigate back to the character editing page.
  const handleBack = () => {
    showLoading();
    navigate(`/${eventName}/edit-your-character`, {
      state: { imageUrl: null },
    });
  };

  // Handles the image download process.
  const handleDownload = async () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const img = new Image();
    img.src = finalImageState;

    img.onload = () => {
      const desiredHeight = 1771; // Set your desired height in pixels
      const aspectRatio = img.width / img.height;
      const desiredWidth = desiredHeight * aspectRatio;

      canvas.width = desiredWidth;
      canvas.height = desiredHeight;

      // Fill canvas with white background
      context.fillStyle = "#FFFFFF";
      context.fillRect(0, 0, canvas.width, canvas.height);

      const offsetX = (canvas.width - desiredWidth) / 2;
      const offsetY = (canvas.height - desiredHeight) / 2;

      context.drawImage(img, offsetX, offsetY, desiredWidth, desiredHeight);

      canvas.toBlob(
        (blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "final-image.png";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        "image/png",
        1 // Maximum quality
      );
    };
  };

  // Confirm modal action to approve and download the image.
  const handleConfirm = () => {
    setIsModalOpen(false);
    handleApprove(true);
  };

  // Opens modal if download flow is enabled, otherwise directly approve.
  const handleOpenModal = () => {
    if (CONFIG.ENABLE_DOWNLOAD_FLOW) {
      setIsModalOpen(true);
    } else {
      handleApprove(false);
    }
  };

  // Close modal and proceed without download.
  const handleCloseModal = () => {
    setIsModalOpen(false);
    handleApprove(false);
  };

  // Cancel modal action.
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="swingie-result-container">
      <ToastContainer />
      <h1 className="swingie-result-title rtl">אשרו את בחירתכם</h1>
      {CONFIG.ENABLE_DOWNLOAD_FLOW && (
        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
      {gifUrl && (
        <div className="swingie-result-gif-overlay">
          <img
            src={gifUrl}
            alt="Status GIF"
            className="swingie-result-status-gif"
          />
        </div>
      )}

      {/* מיכל התמונה */}
      <div className="swingie-result-image-wrapper">
        {finalImageState ? (
          <img
            src={finalImageState}
            alt="Final Result"
            className="swingie-result-image"
          />
        ) : (
          <p>No image to display</p>
        )}
      </div>

      <div className="swingie-result-button-container">
        <button className="swingie-result-footer-button" onClick={handleBack}>
          חזרה
        </button>
        <button
          className="swingie-result-footer-button"
          onClick={handleOpenModal}
        >
          אשר&nbsp;ושלח
        </button>
      </div>
    </div>
  );
};

export default SwingieResultPage;
