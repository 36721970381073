import React, { useRef } from "react";
import { FaSync } from "react-icons/fa";
import "./styles.css";

const EventsList = ({
  events,
  selectedEvent,
  eventStatuses,
  onEventClick,
  onRefresh,
}) => {
  const scrollablePanelRef = useRef(null);

  const isEventReady = (event) => {
    return eventStatuses[event.name]?.ready ?? false;
  };

  const isEventInProgress = (event) => {
    const status = eventStatuses[event.name]?.inProgress;
    switch (status) {
      case "waiting":
        return "upcoming";
      case "running":
        return "ongoing";
      case "pass":
        return "completed";
      default:
        return "";
    }
  };

  return (
    <div className="events-panel">
      <div className="events-header">
        <div className="header-left">
          <h2 className="section-header">Existing Events</h2>
        </div>
        <div className="header-right">
          <button className="refresh-button" onClick={onRefresh}>
            <FaSync />
          </button>
        </div>
      </div>
      <div className="scrollable-panel" ref={scrollablePanelRef}>
        <ul className="events-list">
          {events.map((event, index) => (
            <li
              key={index}
              onClick={() => onEventClick(event)}
              className={selectedEvent === event ? "selected" : ""}
            >
              <div className="event-details">
                <span className="event-name">{event.name}</span>
                <span className="event-date">
                  {event.date.toLocaleDateString("en-GB")}
                </span>
              </div>
              <span
                className={`status-indicator ${
                  isEventReady(event) ? "green" : "red"
                }`}
              ></span>
              <span
                className={`work-indicator ${
                  isEventInProgress(event) ? isEventInProgress(event) : ""
                }`}
              ></span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default EventsList;
