// GifContext.js
import React, { createContext, useState, useContext, useEffect } from "react";

const GifContext = createContext();

export const GifProvider = ({ children }) => {
  const [globalGif, setGlobalGif] = useState(null);

  // Check for pending GIF on every render
  useEffect(() => {
    const checkPendingGif = () => {
      const pendingGifData = localStorage.getItem("pendingGif");
      if (pendingGifData) {
        console.log("Found pending GIF data:", pendingGifData);
        try {
          const { gifUrl, navigateTo, duration, timestamp } =
            JSON.parse(pendingGifData);

          // Check if the GIF is not too old (not more than a minute)
          if (Date.now() - timestamp < 60000) {
            // Remove from local storage before displaying
            localStorage.removeItem("pendingGif");
            // Display the GIF
            setGlobalGif(gifUrl);

            setTimeout(() => {
              setGlobalGif(null);
              if (navigateTo) {
                window.location.href = navigateTo;
              }
            }, duration);
          } else {
            // If the GIF is too old, just clear it
            localStorage.removeItem("pendingGif");
          }
        } catch (error) {
          console.error("Error processing pending GIF:", error);
          localStorage.removeItem("pendingGif");
        }
      }
    };

    // Check immediately on load
    checkPendingGif();

    // Check every second for a new GIF
    const intervalId = setInterval(checkPendingGif, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const showGif = (gifUrl, navigateTo = null, duration = 30000) => {
    console.log(`Showing GIF directly: ${gifUrl}, navigateTo: ${navigateTo}`);
    setGlobalGif(gifUrl);

    setTimeout(() => {
      setGlobalGif(null);
      if (navigateTo) {
        window.location.href = navigateTo;
      }
    }, duration);
  };

  return (
    <GifContext.Provider value={{ globalGif, showGif }}>
      {children}
      {globalGif && (
        <div
          className="gif-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <img src={globalGif} alt="Status GIF" className="status-gif" />
        </div>
      )}
    </GifContext.Provider>
  );
};

export const useGif = () => useContext(GifContext);
