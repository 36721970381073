import React from "react";
import { getDynoPrice } from "../../utils/herokuPricing";
import "./styles.css";

function formatPrice(num) {
  // First, ensure the number is rounded to 3 decimal places
  const fixed = num.toFixed(3);
  // Then, parseFloat removes unnecessary trailing zeros
  return parseFloat(fixed).toString();
}

const DynosTable = ({ herokuFormations }) => {
  return (
    <div className="dynos-table">
      <table>
        <thead>
          <tr>
            <th>Process</th>
            <th>Dyno Count</th>
            <th>Plan</th>
            <th>Total Cost</th>
          </tr>
        </thead>
        <tbody>
          {herokuFormations
            .filter((formation) => formation.quantity > 0)
            .map((formation, idx) => {
              const dynoPrice = getDynoPrice(formation.size.toLowerCase());
              return (
                <tr key={idx}>
                  <td>{formation.process_type}</td>
                  <td>{formation.quantity}</td>
                  <td>
                    <div>
                      <span>{formation.size}</span>
                      <small className="plan-details">
                        {dynoPrice?.compute || "N/A"} <br />
                        {dynoPrice?.ram || "N/A"}
                      </small>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>
                        $
                        {formatPrice(
                          (dynoPrice?.month || 0) * formation.quantity
                        )}
                      </span>
                      <br />
                      <small>
                        $
                        {formatPrice(
                          (dynoPrice?.hour || 0) * formation.quantity
                        )}
                        /hour
                      </small>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default DynosTable;
