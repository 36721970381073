/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ImageGrid from "./BodyImageGrid";
import { fetchData, showAlert } from "../../../../generalUtils";
import { useLoading } from "../../../../Components/Common/LoadingSpinner/LoadingContext";
import { useAppContext } from "../../../../AppContext";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "./SwingieBodyGallery.css";
import {
  getImageBlobFromDB,
  getLastUpdateTime,
  saveBodyImageToDB,
  updateLastUpdateTime,
  getAllKeysFromDB,
  deleteImageFromDB,
  BODY_STORE_NAME,
} from "../../../../IndexedDBUtils";

// Time in seconds before refreshing body images from server
const BODY_REFRESH_INTERVAL_SECONDS = 30;

const SwingieBodyGalleryPage = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const {
    eventName,
    images,
    setImages,
    selectedImages,
    setSelectedImages,
    totalValue,
    setTotalValue,
  } = useAppContext();

  const number = parseInt(sessionStorage.getItem("number")) || 1;
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();

  useEffect(() => {
    const cleanupPreviousBodyImages = () => {
      const bodyUrls = JSON.parse(
        sessionStorage.getItem("bodyImageBlobUrls") || "{}"
      );
      Object.values(bodyUrls).forEach((url) => {
        if (url) URL.revokeObjectURL(url);
      });
      sessionStorage.removeItem("bodyImageBlobUrls");
    };

    const loadImages = async () => {
      const lastUpdateTime = await getLastUpdateTime(BODY_STORE_NAME);
      const currentTime = new Date();
      const timeDifference = (currentTime - new Date(lastUpdateTime)) / 1000;

      if (timeDifference < BODY_REFRESH_INTERVAL_SECONDS) {
        await loadImagesFromDB();
      } else {
        await fetchDataFromServer();
      }
    };

    const loadImagesFromDB = async () => {
      const dbImages = [];
      const allKeys = await getAllKeysFromDB(BODY_STORE_NAME);

      for (const path of allKeys) {
        const imageBlob = await getImageBlobFromDB(BODY_STORE_NAME, path);
        if (imageBlob) {
          const imageUrl = URL.createObjectURL(imageBlob);
          dbImages.push({ path, url: imageUrl });
        }
      }
      setImages(dbImages);

      if (dbImages.length === 0) {
        fetchDataFromServer();
      }
      hideLoading();
    };

    const fetchDataFromServer = async () => {
      if (eventName) {
        const encodedEventName = encodeURIComponent(eventName);
        showLoading();
        try {
          const data = await fetchData(
            serverUrl,
            "/api/bodies",
            encodedEventName
          );

          const keysFromDB = await getAllKeysFromDB(BODY_STORE_NAME);

          // Find new paths to add
          const newPaths = data.filter((path) => !keysFromDB.includes(path));
          // Find paths to delete
          const pathsToDelete = keysFromDB.filter(
            (path) => !data.includes(path)
          );

          if (newPaths.length === 0 && pathsToDelete.length === 0) {
            await loadImagesFromDB();
            hideLoading();
            updateLastUpdateTime(BODY_STORE_NAME);
            return;
          }

          const deletePromises = pathsToDelete.map(async (path) => {
            await deleteImageFromDB(BODY_STORE_NAME, path);
          });
          await Promise.all(deletePromises);

          const imagePromises = newPaths.map(async (path) => {
            const response = await fetch(
              `${serverUrl}/image${path}?size=thumbnail`
            );
            if (!response.ok) {
              console.error(`Failed to fetch image for path: ${path}`);
              return;
            }
            const blob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = async () => {
              const base64Data = reader.result;
              await saveBodyImageToDB(path, base64Data);
              console.log(`Image saved to IndexedDB: ${path}`);
            };
          });
          await Promise.all(imagePromises);

          setImages(
            data.map((path) => ({
              path,
              url: `${serverUrl}/image${path}?size=thumbnail`,
            }))
          );

          await updateLastUpdateTime(BODY_STORE_NAME);
        } catch (error) {
          console.error("Error fetching bodies:", error);
        } finally {
          hideLoading();
        }
      } else {
        console.error("BodyGallery - eventName is missing.");
      }
    };

    cleanupPreviousBodyImages();
    loadImages();
    hideLoading();
  }, [serverUrl, eventName]);

  useEffect(() => {
    setSelectedImages([]);
    setTotalValue(0);
  }, [setSelectedImages, setTotalValue]);

  // Handles body image selection and validates team count compatibility
  const toggleImageSelection = (image) => {
    const imageValue = parseInt(image.split("-").pop().split(".")[0][0]);
    const teamCount = parseInt(image.split("-").pop().split(".")[0][1]);
    const message =
      teamCount === 1
        ? "בחרת תמונה שמתאימה רק ליחיד - אנא שנה את בחירתך בעמוד הקודם או תבחר גוף אחר"
        : "בחרת תמונה שמתאימה רק לזוגי - אנא שנה את בחירתך בעמוד הקודם או תבחר גוף אחר";

    if (teamCount < number) {
      showAlert("warn", message, 6000);
      return;
    }

    if (selectedImages.includes(image)) {
      setSelectedImages(selectedImages.filter((img) => img !== image));
      setTotalValue(totalValue - imageValue);
    } else {
      if (totalValue + imageValue <= number) {
        setSelectedImages([...selectedImages, image]);
        setTotalValue(totalValue + imageValue);
      }
    }
  };

  const goNext = async () => {
    showLoading();
    try {
      const downloadedBodyImages = {};
      for (const path of selectedImages) {
        try {
          const response = await fetch(`${serverUrl}/image${path}?size=full`);
          if (!response.ok) {
            console.error(
              `Failed to fetch image for path: ${path}. Status: ${response.status}`
            );
            continue;
          }
          const imageBlob = await response.blob();
          const blobUrl = URL.createObjectURL(imageBlob);
          downloadedBodyImages[path] = blobUrl;
        } catch (error) {
          console.error("Error fetching body image:", error);
        }
      }
      sessionStorage.setItem("selectedBodies", JSON.stringify(selectedImages));
      sessionStorage.setItem(
        "bodyImageBlobUrls",
        JSON.stringify(downloadedBodyImages)
      );
      navigate(`/${eventName}/edit-your-character`);
    } catch (error) {
      console.error("Error in goNext function:", error);
    } finally {
      hideLoading();
    }
  };

  const goBack = async () => {
    showLoading();
    navigate(`/${eventName}/face-gallery`);
  };

  return (
    <div className="swingie-bodygallery-container">
      <ToastContainer />
      <h1 className="swingie-bodygallery-title rtl">בחרו את הדמות שתרצו</h1>
      <ImageGrid
        images={images}
        selectedImages={selectedImages}
        toggleImageSelection={toggleImageSelection}
        totalValue={totalValue}
        number={number}
        serverUrl={serverUrl}
      />
      <div className="swingie-bodygallery-button-container">
        <button className="swingie-bodygallery-button" onClick={goBack}>
          חזרה
        </button>
        <button
          className={`swingie-bodygallery-button ${
            totalValue === number ? "" : "swingie-bodygallery-disabled-button"
          }`}
          onClick={goNext}
          disabled={totalValue !== number}
        >
          <span>הבא </span>
          <span style={{ fontSize: "18px", fontWeight: "normal" }}>
            ({totalValue}/{number})
          </span>
        </button>
      </div>
    </div>
  );
};

export default SwingieBodyGalleryPage;
