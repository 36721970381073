/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  LoadingProvider,
  useLoading,
} from "./Components/Common/LoadingSpinner/LoadingContext";
import LoadingSpinner from "./Components/Common/LoadingSpinner/LoadingSpinner";
import AdminPanel from "./Pages/AdminPanel/AdminPanel";
import WhatsAppButton from "./Components/Common/WhatsAppButton/WhatsAppButton";
import EventNotFound from "./Pages/NonAppPages/EventNotFound/EventNotFound";
import EventStatusPage from "./Pages/NonAppPages/EventStatusPage/EventStatusPage";
import { fetchData } from "./generalUtils";
import { deleteDatabase } from "./IndexedDBUtils";
import { GifProvider } from "./GifContext";
import Login from "./Login";

// Swingies Components
import SwingieHomePage from "./Pages/AttractionApps/Swingies/Home/SwingieHome";
import SwingieFaceGalleryPage from "./Pages/AttractionApps/Swingies/FaceGallery/SwingieFaceGallery";
import SwingieBodyGalleryPage from "./Pages/AttractionApps/Swingies/BodyGallery/SwingieBodyGallery";
import SwingieEditYourCharacterPage from "./Pages/AttractionApps/Swingies/EditYourCharacter/SwingieEditYourCharacter";
import SwingieResultPage from "./Pages/AttractionApps/Swingies/Result/SwingieResult";

// MiniMove Components for development
import MinimoveHomePage from "./Pages/AttractionApps/MiniMove/Home/MinimoveHome";
import MinimoveFaceGalleryPage from "./Pages/AttractionApps/MiniMove/FaceGallery/MinimoveFaceGallery";
import MinimoveBodyGalleryPage from "./Pages/AttractionApps/MiniMove/BodyGallery/MinimoveBodyGallery";
import MinimoveResultPage from "./Pages/AttractionApps/MiniMove/Result/MinimoveResult";

const serverUrl = process.env.REACT_APP_SERVER_URL;

// This function returns the appropriate components for an event based on its name
const getAttractionComponents = (eventName) => {
  // Extract the event type from the event name (e.g., "swingies" or "minimove")
  const eventType = eventName.split("_")[0].toLowerCase();

  // Return the appropriate components based on the event type
  switch (eventType) {
    case "event":
    case "swingies":
      return {
        Home: SwingieHomePage,
        FaceGallery: SwingieFaceGalleryPage,
        BodyGallery: SwingieBodyGalleryPage,
        EditYourCharacter: SwingieEditYourCharacterPage,
        Result: SwingieResultPage,
      };
    case "minimove":
      return {
        Home: MinimoveHomePage,
        FaceGallery: MinimoveFaceGalleryPage,
        BodyGallery: MinimoveBodyGalleryPage,
        Result: MinimoveResultPage,
      };
    default:
      return null;
  }
};

const App = () => {
  return (
    <Router>
      <GifProvider>
        <LoadingProvider>
          <EnsureHomeOnReload>
            <Routes>
              {/* Define routes for different event paths */}
              <Route
                exact
                path="/:eventName/:page?"
                element={<EventWrapper />}
              />
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<AdminPanel />} />
              <Route path="*" element={<EventNotFound />} />
            </Routes>
            {/* Show WhatsApp button on all pages except the admin panel */}
            {window.location.pathname !== "/" && <WhatsAppButton />}
          </EnsureHomeOnReload>
          <LoadingHandler />
        </LoadingProvider>
      </GifProvider>
    </Router>
  );
};

const EventWrapper = () => {
  const [eventData, setEventData] = useState(null); // Holds event data fetched from the server
  const [components, setComponents] = useState(null); // Holds the components for the current event
  const location = useLocation();

  useEffect(() => {
    // Extract event name from the URL path
    const eventName = window.location.pathname.split("/")[1];
    const encodedEventName = encodeURIComponent(eventName);

    // Save the event name in session storage for later use
    if (encodedEventName) {
      sessionStorage.setItem("event_name", encodedEventName);
    }

    // Check if the event exists by making an API call
    const checkEventExists = async () => {
      let data;
      try {
        data = await fetchData(serverUrl, "/api/check-event", encodedEventName);
        if (data.exists) {
          // Set the appropriate components for the current event
          setComponents(getAttractionComponents(eventName));
        }
        setEventData(data);
      } catch (error) {
        console.log("Error checking event:", error);
        setEventData({ exists: false });
      }
    };

    checkEventExists();
  }, []);

  // Show loading spinner while event data is being fetched
  if (!eventData) {
    return <LoadingSpinner />;
  }

  // If the event does not exist, show the EventNotFound page
  if (!eventData.exists) {
    deleteDatabase("ImageDatabase"); // Delete cached images if event not found
    return <EventNotFound />;
  }

  // If the event is not currently running, show the EventStatusPage
  if (eventData.progressStatus !== "running") {
    deleteDatabase("ImageDatabase"); // Delete cached images if event is not running
    return (
      <EventStatusPage
        status={eventData.progressStatus}
        startTime={eventData.startTime}
      />
    );
  }

  // Show loading spinner while components are being set
  if (!components) {
    return <LoadingSpinner />;
  }

  // Destructure the components object to get the specific components for the event
  const {
    Home,
    FaceGallery,
    BodyGallery,
    EditYourCharacter = null,
    Result,
  } = components;

  // Determine which component to render based on the current page
  const page = location.pathname.split("/")[2];
  switch (page) {
    case "face-gallery":
      return <FaceGallery />;
    case "body-gallery":
      return <BodyGallery />;
    case "edit-your-character":
      return EditYourCharacter ? (
        <EditYourCharacter />
      ) : (
        <Home reFace={eventData.reFace} />
      );
    case "result":
      return <Result />;
    case "status":
      return (
        <EventStatusPage
          status={eventData.progressStatus}
          startTime={eventData.startTime}
        />
      );
    default:
      return <Home reFace={eventData.reFace} />;
  }
};

const EnsureHomeOnReload = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Ensure that on reload, the user is navigated to the correct event page
    const navigationEntries = performance.getEntriesByType("navigation");
    if (
      navigationEntries.length > 0 &&
      navigationEntries[0].type === "reload"
    ) {
      if (location.pathname !== "/") {
        const eventName = location.pathname.split("/")[1];
        navigate(`/${eventName}`);
      }
    }
  }, []);

  return children;
};

const LoadingHandler = () => {
  const { isLoading } = useLoading();
  // Show loading spinner if any component is currently loading
  return isLoading ? <LoadingSpinner /> : null;
};

export default App;
