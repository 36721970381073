/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchEvents, parseEventName } from "./utils/utils";
import EventPopup from "./components/EventPopup";
import { getAllPricing } from "./utils/herokuPricing";
import "./AdminPanel.css";
import BillingHeader from "./components/BillingHeader";
import CreateEventForm from "./components/CreateEventForm";
import RedisInfoSection from "./components/RedisInfo";
import DynosTable from "./components/DynosTable";
import EventsList from "./components/EventsList";
import ResizeImages from "./components/ResizeImages";
import "react-datepicker/dist/react-datepicker.css";
import { useLoading } from "../../Components/Common/LoadingSpinner/LoadingContext";

const AdminPanel = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventStatuses, setEventStatuses] = useState({});

  const [herokuFormations, setHerokuFormations] = useState([]);

  const [redisAvailable, setRedisAvailable] = useState(true);
  const [redisStatus, setRedisStatus] = useState("");

  const [usedMemory, setUsedMemory] = useState(0);
  const [maxMemory, setMaxMemory] = useState(0);

  const [selectedOption, setSelectedOption] = useState("Required Usage");

  const [pricing, setPricing] = useState(null);
  const [billingInfo, setBillingInfo] = useState({
    total: "",
    periodEnd: null,
  });

  const { showLoading, hideLoading } = useLoading();
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  // ---------------------------------------------------------------------
  //                         (Billing Info)
  // ---------------------------------------------------------------------
  const fetchBillingInfo = async () => {
    try {
      const response = await fetch(`${serverUrl}/api/heroku-billing`);
      if (response.ok) {
        const data = await response.json();
        setBillingInfo({
          total: data.total_charges_this_month,
          periodEnd: data.period_end,
        });
      }
    } catch (error) {
      console.error("Error fetching billing info:", error);
    }
  };

  // ---------------------------------------------------------------------
  //                              Data Loading
  // ---------------------------------------------------------------------
  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const loadData = async () => {
      showLoading();
      const today = new Date().toISOString().split("T")[0];
      if (!billingInfo.periodEnd || billingInfo.periodEnd !== today) {
        await fetchBillingInfo();
      }
      // console.log("loadEvents!");
      await loadEvents();
      await fetchHerokuPlan();
      hideLoading();
    };

    loadData();

    const intervalId = setInterval(() => {
      if (document.hidden) return;
      loadEvents();
      fetchHerokuPlan();
      const today = new Date().toISOString().split("T")[0];
      if (!billingInfo.periodEnd || billingInfo.periodEnd !== today) {
        fetchBillingInfo();
      }
    }, 55000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (redisAvailable) {
      const memoryMap = {
        25: "mini",
        50: "premium-0",
        100: "premium-1",
        250: "premium-2",
        500: "premium-3",
        1024: "premium-5",
      };
      const defaultOption = memoryMap[maxMemory];
      if (defaultOption) {
        setSelectedOption(defaultOption);
      }
    }
  }, [redisAvailable, maxMemory]);

  // ---------------------------------------------------------------------
  //                       Loading Events (function)
  // ---------------------------------------------------------------------
  const loadEvents = async () => {
    try {
      fetchEvents(
        `${serverUrl}/api/events-managements`,
        async (fetchedEvents) => {
          const parsedEvents = fetchedEvents
            .map((event) => {
              try {
                return parseEventName(event);
              } catch (e) {
                console.error(e.message);
                return null;
              }
            })
            .filter(Boolean)
            .sort((a, b) => new Date(a.date) - new Date(b.date));
          setEvents(parsedEvents);

          const response = await fetch(`${serverUrl}/api/events-status`);
          const statuses = await response.json();
          setEventStatuses(
            Object.values(statuses).reduce((acc, status) => {
              acc[status.event_name] = {
                ...status,
                startTime: status.startTime,
                endTime: status.endTime,
              };
              return acc;
            }, {})
          );
          // console.log("Event statuses:", statuses);
        }
      );
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // ---------------------------------------------------------------------
  //                Loading Heroku Plan (formation + redis)
  // ---------------------------------------------------------------------
  const fetchHerokuPlan = async () => {
    try {
      const response = await fetch(`${serverUrl}/api/heroku-redis-plan`);
      if (!response.ok) {
        throw new Error("Failed to fetch Heroku dyno type");
      }
      const data = await response.json();
      setHerokuFormations(data.formations || []); // Added formations to state
      // console.log("Heroku formations:", JSON.stringify(herokuFormations));
      const usedMemory = parseFloat(data.used_memory_mb) || 0; // Added used memory to state
      const maxMemory = parseFloat(data.max_memory_mb) || 0; // Added max memory to state
      setUsedMemory(usedMemory);
      setMaxMemory(maxMemory);
      setRedisAvailable(data.redis_available);
      setRedisStatus(data.redis_status);
      const newPricing = getAllPricing(data.formations, data.redis_plan); // Added formations and redis plan to function call
      setPricing(newPricing);
    } catch (error) {
      console.error("Error fetching Heroku dyno type:", error);
    }
  };

  // ---------------------------------------------------------------------
  //                         Handling Event Selection
  // ---------------------------------------------------------------------
  const handleEventClick = (event) => {
    try {
      const status = eventStatuses[event.name] || {};
      const startTimeFormatted = status.startTime || "01/01/1970 00:00";
      const endTimeFormatted = status.endTime || "01/01/1970 00:00";
      setSelectedEvent({
        ...event,
        ...status,
        startTimeFormatted,
        endTimeFormatted,
      });
    } catch (e) {
      console.error(`e: ${e}`);
    }
  };

  const handleClosePopup = () => {
    setSelectedEvent(null);
    loadEvents();
  };

  // ---------------------------------------------------------------------
  //              Manual refresh of event status (Refresh button)
  // ---------------------------------------------------------------------
  const handleRefresh = async () => {
    try {
      showLoading();
      const response = await fetch(`${serverUrl}/api/update-events-status`, {
        method: "POST",
      });
      if (response.ok) {
        await loadEvents();
        await fetchHerokuPlan();
      } else {
        hideLoading();
        console.error("Failed to refresh events status");
      }
    } catch (error) {
      hideLoading();
      console.error("Error refreshing events status:", error);
    } finally {
      hideLoading();
    }
    hideLoading();
  };

  // ---------------------------------------------------------------------
  //                               Rendering
  // ---------------------------------------------------------------------
  if (loading) {
    return <div>Loading.</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="admin-panel-container">
      <div className="heroku-status-container">
        <BillingHeader billingInfo={billingInfo} pricing={pricing} />

        <DynosTable herokuFormations={herokuFormations} />
        <RedisInfoSection
          usedMemory={usedMemory}
          maxMemory={maxMemory}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          redisAvailable={redisAvailable}
          redisStatus={redisStatus}
          pricing={pricing}
          onPlanUpdate={async () => {
            await loadEvents();
            await fetchHerokuPlan();
          }}
          serverUrl={serverUrl}
        />
      </div>

      <EventsList
        events={events}
        selectedEvent={selectedEvent}
        eventStatuses={eventStatuses}
        onEventClick={handleEventClick}
        onRefresh={handleRefresh}
      />

      <CreateEventForm
        serverUrl={serverUrl}
        onEventCreated={(createdEvent) => {
          setEvents((prevEvents) => [
            ...prevEvents,
            parseEventName(createdEvent.folder_name),
          ]);
        }}
      />

      <ResizeImages serverUrl={serverUrl} />

      {selectedEvent && (
        <EventPopup
          event={selectedEvent}
          onClose={handleClosePopup}
          loadEvents={loadEvents}
          eventStatuses={eventStatuses}
        />
      )}
    </div>
  );
};

export default AdminPanel;
