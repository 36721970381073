/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../AppContext";
import { useLoading } from "../../../../Components/Common/LoadingSpinner/LoadingContext";
import {
  getAllKeysFromDB,
  getImageBlobFromDB,
  getFullObjectFromDB,
  BODY_STORE_NAME,
  FACE_STORE_NAME,
} from "../../../../IndexedDBUtils";

import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";

import "./MinimoveBodyGallery.css";

const MinimoveBodyGalleryPage = () => {
  const { showLoading, hideLoading } = useLoading();
  const { eventName } = useAppContext();
  const navigate = useNavigate();
  const sliderContainerRef = useRef(null);

  // Define the desired face size as percentage of viewport height
  const DESIRED_FACE_SIZE_VH = 15; // 20% of viewport height

  // Extract actual face size from filename
  const extractFaceSizeFromPath = (path) => {
    try {
      // Path looks like "path/to/imageFaceName+350.png"
      const match = path.match(/\+(\d+)\.[^.]+$/);
      if (match && match[1]) {
        return parseInt(match[1], 10);
      }
    } catch (error) {
      console.error("Error extracting face size from path:", error);
    }
    // Default if we can't extract
    return null;
  };

  // Face parameters - cubic bezier for animation timing
  // Fine-tuned for more elegant movement
  const FACE_TRANSITION_DURATION = 0.3; // Increased for smoother animation (was 0.15)
  const FACE_CUBIC_X1 = 0.25;
  const FACE_CUBIC_Y1 = 0.1; // Reduced from 0.9 to create more gentle start
  const FACE_CUBIC_X2 = 0.5; // Changed from 0.9 to 0.5 to start deceleration sooner
  const FACE_CUBIC_Y2 = 1.25; // Reduced from 1.4 to make bounce more subtle

  // Slider animation parameters
  const SLIDER_ANIMATION_DURATION = 350; // Increased from 250 for smoother transition
  const ADDITIONAL_DELAY = 50; // milliseconds

  // State variables
  const [images, setImages] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [selectedFace, setSelectedFace] = useState(null);
  const [facePosition, setFacePosition] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [faceVisible, setFaceVisible] = useState(true);
  const [sliderAnimating, setSliderAnimating] = useState(false);

  // Reference to previous slide for animation direction
  const prevSlideRef = useRef(0);

  // References for slider dimensions
  const sliderDimensionsRef = useRef({ width: 0, height: 0 });

  // References for body images
  const imageRefsRef = useRef([]);

  // Flag to track if slider is currently animating
  const isAnimatingRef = useRef(false);

  // Simple function to handle slide change
  const handleSlideChange = (s) => {
    try {
      const idx = s.track.details.rel;

      // Set animating flag to true
      isAnimatingRef.current = true;
      setSliderAnimating(true);

      // Always hide face at the start of any slide transition
      setFaceVisible(false);

      // Update current slide in state
      setCurrentSlide(idx);
      console.log("Slide changed to index:", idx);

      // Schedule face to reappear before the animation completes (at 70% of the way)
      setTimeout(() => {
        updateFacePosition(idx);
        setFaceVisible(true);
      }, SLIDER_ANIMATION_DURATION * 0.7);
    } catch (err) {
      console.error("Error in handleSlideChange:", err);
    }
  };

  // Function to handle slide change completion
  const handleSlideChangeComplete = () => {
    console.log("Slide animation completed");
    isAnimatingRef.current = false;
    setSliderAnimating(false);
  };

  // Standard slider configuration
  const [sliderRef, sliderInstance] = useKeenSlider({
    loop: true,
    mode: "free-snap",
    rubberband: false,
    slides: {
      perView: 1.3, // Decreased slightly for better face animation visibility
      spacing: 1,
      origin: "center",
    },
    dragSpeed: 1.2,
    defaultAnimation: {
      duration: SLIDER_ANIMATION_DURATION,
    },
    created: () => {
      console.log("Slider created");
      setTimeout(() => {
        if (sliderContainerRef.current) {
          sliderDimensionsRef.current = {
            width: sliderContainerRef.current.offsetWidth,
            height: sliderContainerRef.current.offsetHeight,
          };
        }
      }, 100);
    },
    slideChanged: handleSlideChange,
    animationEnded: handleSlideChangeComplete,
  });

  // Update face position - improved with proper face size scaling
  const updateFacePosition = (slideIndex = currentSlide) => {
    console.log(`Updating face position for slide: ${slideIndex}`);

    if (
      images.length === 0 ||
      slideIndex < 0 ||
      slideIndex >= images.length ||
      !images[slideIndex].facePosition ||
      !selectedFace
    ) {
      console.warn("Cannot update face position - invalid data");
      return;
    }

    const currentImage = images[slideIndex];

    // Get the current image element
    const imgElement = document.querySelector(
      `.keen-slider__slide[data-slide="${slideIndex}"] .minimove-bodygallery-image`
    );

    if (!imgElement) {
      console.error(`Could not find image element for slide ${slideIndex}`);
      return;
    }

    const anchorPoint = currentImage.facePosition;
    const originalWidth = currentImage.width || 1;
    const originalHeight = currentImage.height || 1;

    // Get image dimensions
    const imageRect = imgElement.getBoundingClientRect();
    const currentWidth = imageRect.width;
    const currentHeight = imageRect.height;

    // Calculate scale
    const scaleX = currentWidth / originalWidth;
    const scaleY = currentHeight / originalHeight;

    // Calculate face size based on viewport height and actual face size
    const viewportHeight = window.innerHeight;
    const desiredFaceSize = (viewportHeight * DESIRED_FACE_SIZE_VH) / 100;

    // Calculate scaling factor based on actual face size from filename
    const actualFaceSize = selectedFace.actualFaceSize;
    const scaleFactor = desiredFaceSize / actualFaceSize;

    // Calculate final size for the face image
    const faceImageHeight = actualFaceSize * scaleFactor;

    console.log(`Viewport Height: ${viewportHeight}px`);
    console.log(
      `Desired Face Size: ${desiredFaceSize}px (${DESIRED_FACE_SIZE_VH}% of viewport)`
    );
    console.log(`Actual Face Size from filename: ${actualFaceSize}px`);
    console.log(`Scale Factor: ${scaleFactor}`);
    console.log(`Final Face Image Height: ${faceImageHeight}px`);

    // Calculate position
    const absoluteX =
      imageRect.left + anchorPoint.x * scaleX - faceImageHeight / 2;
    const absoluteY =
      imageRect.top + anchorPoint.y * scaleY - faceImageHeight / 2;

    console.log(
      `Face position calculated: x=${absoluteX}, y=${absoluteY}, size=${faceImageHeight}`
    );

    // Update position state
    setFacePosition({
      x: absoluteX,
      y: absoluteY,
      width: faceImageHeight,
      height: faceImageHeight,
    });
  };

  // Load selected face from previous page
  useEffect(() => {
    const loadSelectedFace = async () => {
      try {
        const selectedFacesJson = sessionStorage.getItem("selectedFaces");
        if (!selectedFacesJson) {
          console.log("No selected faces found");
          return;
        }

        const selectedFaces = JSON.parse(selectedFacesJson);
        if (!selectedFaces || !selectedFaces.length) {
          console.log("Empty selected faces array");
          return;
        }

        const facePath = selectedFaces[0];
        console.log("Loading face from path:", facePath);

        // Extract face size from the path
        const faceSize = extractFaceSizeFromPath(facePath);
        console.log("Extracted face size from filename:", faceSize);

        const faceBlob = await getImageBlobFromDB(FACE_STORE_NAME, facePath);
        if (faceBlob) {
          const faceUrl = URL.createObjectURL(faceBlob);
          setSelectedFace({
            url: faceUrl,
            actualFaceSize: faceSize || 350, // Use 350 as default if can't extract
          });
          setFaceVisible(false); // Start with face hidden until positioning
          console.log("Face loaded successfully");
        } else {
          console.error("Failed to load face from IndexedDB");
        }
      } catch (error) {
        console.error("Error loading selected face:", error);
      }
    };

    loadSelectedFace();
  }, []);

  // Image validity check
  const checkImageValidity = useCallback(async (blob) => {
    return new Promise((resolve) => {
      if (!blob) {
        resolve(false);
        return;
      }

      try {
        const img = new Image();
        const objectUrl = URL.createObjectURL(blob);

        img.onload = () => {
          URL.revokeObjectURL(objectUrl);
          resolve(true);
        };

        img.onerror = () => {
          URL.revokeObjectURL(objectUrl);
          resolve(false);
        };

        img.src = objectUrl;
      } catch (err) {
        console.error("Error checking image validity:", err);
        resolve(false);
      }
    });
  }, []);

  // Initialize image references
  useEffect(() => {
    imageRefsRef.current = Array(images.length)
      .fill()
      .map((_, index) => imageRefsRef.current[index] || React.createRef());
  }, [images.length]);

  // Load body images from database
  useEffect(() => {
    const loadImagesFromDB = async () => {
      showLoading();
      try {
        const allKeys = await getAllKeysFromDB(BODY_STORE_NAME);
        console.log(`Found ${allKeys.length} body images in DB`);

        if (allKeys.length === 0) {
          setImages([]);
          setImagesLoaded(true);
          hideLoading();
          return;
        }

        let loadedImages = [];

        for (const path of allKeys) {
          try {
            const bodyObject = await getFullObjectFromDB(BODY_STORE_NAME, path);
            if (!bodyObject || !bodyObject.imageData) continue;

            const blob = bodyObject.imageData;
            const isValid = await checkImageValidity(blob);
            if (!isValid) continue;

            const objectUrl = URL.createObjectURL(blob);

            const imageObject = {
              path,
              url: objectUrl,
              facePosition: bodyObject.face_position,
              width: bodyObject.width || 0,
              height: bodyObject.height || 0,
            };

            loadedImages.push(imageObject);
          } catch (err) {
            console.error(`Error processing image ${path}:`, err);
          }
        }

        setImages(loadedImages);
        setImagesLoaded(true);
      } catch (err) {
        console.error("Error loading body images:", err);
        setImagesLoaded(true);
      } finally {
        hideLoading();
      }
    };

    loadImagesFromDB();
  }, [checkImageValidity]);

  // Update face position when slider is ready
  useEffect(() => {
    if (images.length > 0 && sliderInstance && sliderInstance.current) {
      setTimeout(() => {
        sliderInstance.current.update();
        console.log("Slider updated, will update face position");

        setTimeout(() => {
          updateFacePosition(currentSlide);
          prevSlideRef.current = currentSlide;
        }, 300);
      }, 300);
    }
  }, [images.length, sliderInstance]);

  // Update face when images are fully loaded
  useEffect(() => {
    if (images.length > 0 && imagesLoaded && selectedFace) {
      console.log("Images fully loaded, updating face position");

      // Make multiple attempts with increasing delays
      [500, 1000, 2000].forEach((delay) => {
        setTimeout(() => {
          if (!isAnimatingRef.current) {
            updateFacePosition();
            setFaceVisible(true);
          }
        }, delay);
      });
    }
  }, [images, imagesLoaded, selectedFace]);

  // Update face when slide changes
  useEffect(() => {
    if (
      images.length > 0 &&
      currentSlide >= 0 &&
      currentSlide < images.length &&
      prevSlideRef.current !== currentSlide // Only process if slide actually changed
    ) {
      console.log("Slide changed, handling face update with animation");

      // Store previous slide for next comparison
      prevSlideRef.current = currentSlide;

      // Note: The face visibility and timing are now handled in handleSlideChange
      // This effect is only for additional position updates if needed

      // Make a final position update after animation completes for accuracy
      const totalDelay = SLIDER_ANIMATION_DURATION + ADDITIONAL_DELAY;
      setTimeout(() => {
        console.log("Animation complete, final position update");
        updateFacePosition(currentSlide);
      }, totalDelay);
    }
  }, [currentSlide, images]);

  // Effect to handle animation state changes
  useEffect(() => {
    if (!sliderAnimating && images.length > 0) {
      console.log("Slider animation completed, updating face position");
      updateFacePosition(currentSlide);
      setFaceVisible(true);
    }
  }, [sliderAnimating]);

  // Listen for window resize
  useEffect(() => {
    const handleResize = () => {
      if (images.length > 0 && currentSlide >= 0) {
        console.log("Window resized, updating face position");
        updateFacePosition(currentSlide);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [images, currentSlide]);

  // Consistent handler for navigation methods
  const navigateToSlide = (action) => {
    if (!sliderInstance?.current || isAnimatingRef.current) return;

    // Mark as animating
    isAnimatingRef.current = true;
    setSliderAnimating(true);

    // Always hide face first for all navigation methods
    setFaceVisible(false);

    // Execute navigation action
    if (typeof action === "number") {
      // For thumbnail clicks
      sliderInstance.current.moveToIdx(action);
    } else if (action === "next") {
      // For next arrow
      sliderInstance.current.next();
    } else if (action === "prev") {
      // For prev arrow
      sliderInstance.current.prev();
    }

    // Note: face re-appearance is handled in handleSlideChange
    // which will be called as a result of the navigation action
  };

  // Thumbnail click handler
  const handleThumbClick = (idx) => {
    navigateToSlide(idx);
  };

  // Next slide handler
  const handleNextSlide = () => {
    navigateToSlide("next");
  };

  // Previous slide handler
  const handlePrevSlide = () => {
    navigateToSlide("prev");
  };

  // Finish button handler
  const handleFinish = () => {
    if (images.length > 0) {
      const chosenBodyPath = images[currentSlide].path;
      sessionStorage.setItem(
        "selectedBodies",
        JSON.stringify([chosenBodyPath])
      );
    }
    navigate(`/${eventName}/result`);
  };

  // Back button handler
  const handleBack = () => {
    navigate(`/${eventName}/face-gallery`);
  };

  // If no images
  if (imagesLoaded && images.length === 0) {
    return (
      <div className="minimove-bodygallery-container no-scroll">
        <h1 className="minimove-bodygallery-title rtl">אין דמויות זמינות</h1>
        <div className="minimove-bodygallery-buttons">
          <button onClick={handleBack}>חזרה</button>
        </div>
      </div>
    );
  }

  return (
    <div className="minimove-bodygallery-container no-scroll">
      <h1 className="minimove-bodygallery-title rtl">בחרו את הגוף שלכם</h1>

      {/* Thumbnails row */}
      <div className="minimove-thumbnails-row">
        {images.map((item, idx) => (
          <div
            key={idx}
            onClick={() => handleThumbClick(idx)}
            className={`minimove-thumbnail-wrapper ${
              idx === currentSlide ? "active" : ""
            }`}
          >
            <img
              src={item.url}
              alt={`thumb ${idx}`}
              className="minimove-thumbnail-image"
              onError={(e) => {
                e.target.src =
                  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100'%3E%3Crect width='100' height='100' fill='%23cccccc'/%3E%3Ctext x='50' y='50' dominant-baseline='middle' text-anchor='middle' font-family='sans-serif' font-size='14' fill='%23333333'%3EError%3C/text%3E%3C/svg%3E";
              }}
            />
          </div>
        ))}
      </div>

      {/* Main carousel area */}
      <div
        className="minimove-bodygallery-slider-container"
        ref={sliderContainerRef}
      >
        {/* Add left arrow for navigation */}
        <div
          className="slider-nav-arrow slider-nav-left"
          onClick={handlePrevSlide}
        >
          <div className="nav-arrow-indicator left"></div>
        </div>

        <div
          ref={sliderRef}
          className="keen-slider minimove-bodygallery-slider"
        >
          {images.map((item, idx) => {
            return (
              <div
                key={idx}
                className="keen-slider__slide minimove-bodygallery-slide"
                data-slide={idx}
              >
                <div className="image-wrapper" style={{ position: "relative" }}>
                  {/* Body image */}
                  <img
                    ref={imageRefsRef.current[idx]}
                    src={item.url}
                    alt={`Body ${idx}`}
                    className="minimove-bodygallery-image"
                    data-index={idx}
                    onLoad={() => {
                      if (idx === currentSlide && !isAnimatingRef.current) {
                        console.log(
                          `Image ${idx} loaded, updating face position`
                        );
                        setTimeout(() => {
                          updateFacePosition(idx);
                          setFaceVisible(true);
                        }, 300);
                      }
                    }}
                    onError={(e) => {
                      e.target.src =
                        "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='300' height='300'%3E%3Crect width='300' height='300' fill='%23cccccc'/%3E%3Ctext x='150' y='150' dominant-baseline='middle' text-anchor='middle' font-family='sans-serif' font-size='24' fill='%23333333'%3EError loading image%3C/text%3E%3C/svg%3E";
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>

        {/* Add right arrow for navigation */}
        <div
          className="slider-nav-arrow slider-nav-right"
          onClick={handleNextSlide}
        >
          <div className="nav-arrow-indicator right"></div>
        </div>

        {/* Moving face - always visible when loaded and positioned */}
        {selectedFace && faceVisible && (
          <div
            className="moving-face"
            style={{
              position: "fixed",
              left: `${facePosition.x}px`,
              top: `${facePosition.y}px`,
              width: `${facePosition.width}px`,
              height: `${facePosition.height}px`,
              backgroundImage: `url(${selectedFace.url})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              zIndex: 500,
              pointerEvents: "none",
              transition: `all ${FACE_TRANSITION_DURATION}s cubic-bezier(${FACE_CUBIC_X1}, ${FACE_CUBIC_Y1}, ${FACE_CUBIC_X2}, ${FACE_CUBIC_Y2})`,
              transformOrigin: "center center",
            }}
          />
        )}
      </div>

      {/* Navigation buttons */}
      <div className="minimove-bodygallery-buttons">
        <button className="back-button" onClick={handleBack}>
          חזרה
        </button>
        <button
          className="finish-button"
          onClick={handleFinish}
          disabled={images.length === 0}
        >
          סיום
        </button>
      </div>
    </div>
  );
};

export default MinimoveBodyGalleryPage;
