import {
  handleResize as resize,
  handleFlip as flip,
  handleUpdatePosition as updatePosition,
  handleZIndexChange as zIndexChange,
} from "../ImageHandlers";

export const useImageHandlers = (
  activeImage,
  setActiveImage,
  stageRef,
  images,
  setImages,
  selectedFaces,
  selectedBodies
) => {
  const handleResize = (delta) =>
    resize(
      delta,
      setImages,
      activeImage,
      stageRef,
      selectedFaces,
      selectedBodies
    );

  const handleFlip = () => flip(setActiveImage, activeImage, images, setImages);

  const handleUpdatePosition = (src, newPosition, hasMoved = false) =>
    updatePosition(src, newPosition, hasMoved, setImages);

  const handleZIndexChange = (direction) =>
    zIndexChange(direction, activeImage, stageRef);

  return { handleResize, handleFlip, handleUpdatePosition, handleZIndexChange };
};
