import React, { useState, useEffect, useRef } from "react";
import { useLoading } from "../../../../Components/Common/LoadingSpinner/LoadingContext";
import { parseDateString } from "../../utils/utils";
import QRCodePopup from "./QRCodePopup";
import "./styles.css";
import { FaPencilAlt, FaSave, FaQrcode, FaSync } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EventPopup = ({ event, onClose, loadEvents, eventStatuses = {} }) => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { showLoading, hideLoading } = useLoading();
  const [isEditing, setIsEditing] = useState({
    name: false,
    duration: false,
    reusable_heads: false,
    keep_heads: false,
    event_type: false,
    station_count: false, // Added station_count to isEditing state
  });
  const [editedEvent, setEditedEvent] = useState({
    name: event.name,
    startTime: parseDateString(event.startTimeFormatted),
    endTime: parseDateString(event.endTimeFormatted),
    reusable_heads: event.reusable_heads,
    keep_heads: event.keep_heads,
    event_type: event.prefix,
    station_count: event.station_count || 1, // Added station_count with fallback to 1
  });

  // State to control the display of the QR code popup
  const [showQRCode, setShowQRCode] = useState(false);
  // Ref for the area containing the QR icon and popup
  const qrContainerRef = useRef(null);

  useEffect(() => {
    // Listen for clicks in the document and hide QR popup if clicked outside the container
    const handleClickOutside = (event) => {
      if (
        qrContainerRef.current &&
        !qrContainerRef.current.contains(event.target)
      ) {
        setShowQRCode(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleEdit = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: true }));
  };

  const handleRefreshPopup = async () => {
    showLoading();
    try {
      await loadEvents();

      if (event && event.name && eventStatuses[event.name]) {
        const updatedStatus = eventStatuses[event.name];
        const startTimeFormatted =
          updatedStatus.startTime || event.startTimeFormatted;
        const endTimeFormatted =
          updatedStatus.endTime || event.endTimeFormatted;

        setEditedEvent({
          name: event.name,
          startTime: parseDateString(startTimeFormatted),
          endTime: parseDateString(endTimeFormatted),
          reusable_heads:
            updatedStatus.reusable_heads !== undefined
              ? updatedStatus.reusable_heads
              : event.reusable_heads,
          keep_heads:
            updatedStatus.keep_heads !== undefined
              ? updatedStatus.keep_heads
              : event.keep_heads,
          event_type: event.prefix,
          station_count:
            updatedStatus.station_count || event.station_count || 1,
        });
      }
    } catch (error) {
      console.error("Error refreshing event data:", error);
      alert("Failed to refresh data. Please try again.");
    } finally {
      hideLoading();
    }
  };

  // Handler to save edited event field changes to the backend and update the UI
  const handleSave = async (field) => {
    showLoading();
    try {
      let new_value = "";
      if (field === "duration") {
        new_value = {
          startTime: editedEvent.startTime.toLocaleString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }),
          endTime: editedEvent.endTime.toLocaleString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }),
        };
      } else if (field === "reusable_heads") {
        new_value = editedEvent.reusable_heads ? "Yes" : "No";
      } else if (field === "keep_heads") {
        new_value = editedEvent.keep_heads ? "Yes" : "No";
      } else if (field === "event_type") {
        new_value = editedEvent.event_type;
      } else if (field === "station_count") {
        new_value = editedEvent.station_count;
      } else {
        new_value = editedEvent[field];
      }

      const response = await fetch(`${serverUrl}/api/update-event-field`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event_name: event.name,
          event_date: event.date.toLocaleDateString("en-GB"),
          event_type: event.prefix,
          field: field,
          new_value: new_value,
        }),
      });

      if (response.ok) {
        const updatedEvent = { ...event, ...editedEvent };
        setEditedEvent(updatedEvent);
        setIsEditing((prev) => ({ ...prev, [field]: false }));
        await loadEvents();
        if (
          field === "name" ||
          field === "duration" ||
          field === "event_type"
        ) {
          onClose();
        }
      } else {
        console.error("Failed to update event");
      }
    } catch (error) {
      console.error("Error updating event:", error);
    } finally {
      hideLoading();
    }
  };

  const handleNameChange = (e) => {
    const filteredValue = e.target.value.replace(/[^a-zA-Z0-9&]/g, "");
    setEditedEvent({ ...editedEvent, name: filteredValue });
  };

  // Handle change in date fields and ensure end time is after start time
  const handleDateChange = (field, date) => {
    if (
      field === "startTime" &&
      editedEvent.endTime &&
      date >= editedEvent.endTime
    ) {
      setEditedEvent((prevEvent) => ({
        ...prevEvent,
        [field]: date,
        endTime: null,
      }));
    } else {
      setEditedEvent((prevEvent) => ({
        ...prevEvent,
        [field]: date,
      }));
    }
  };

  const handleReusableHeadsChange = (e) => {
    setEditedEvent({
      ...editedEvent,
      reusable_heads: e.target.value === "Yes",
    });
  };

  const handleKeepHeadsChange = (e) => {
    setEditedEvent({
      ...editedEvent,
      keep_heads: e.target.value === "Yes",
    });
  };

  const handleEventTypeChange = (e) => {
    setEditedEvent({
      ...editedEvent,
      event_type: e.target.value,
    });
  };

  // Handler for station count changes
  const handleStationCountChange = (e) => {
    setEditedEvent({
      ...editedEvent,
      station_count: parseFloat(e.target.value, 10),
    });
  };

  if (!event) return null;

  const issues = event.issues || [];

  const getStatusStyle = (status) => {
    switch (status) {
      case "Upcoming":
        return "status-upcoming";
      case "Ongoing":
        return "status-ongoing";
      case "Completed":
        return "status-completed";
      default:
        return "";
    }
  };

  const mapStatusWord = (status) => {
    switch (status) {
      case "waiting":
        return "Upcoming";
      case "running":
        return "Ongoing";
      case "pass":
        return "Completed";
      default:
        return "N/A";
    }
  };

  const mappedStatus = mapStatusWord(event.inProgress);

  // Handler to delete the event after user confirmation
  const handleDelete = async () => {
    const confirmed = window.confirm("האם אתה בטוח שברצונך למחוק את האירוע?");
    if (!confirmed) return;

    try {
      showLoading();
      const response = await fetch(`${serverUrl}/api/delete-event`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event_type: event.prefix,
          event_name: event.name,
          event_date: event.date.toLocaleDateString("en-GB"),
        }),
      });
      if (response.ok) {
        onClose();
      } else {
        hideLoading();
        console.error("Failed to delete event");
      }
    } catch (error) {
      hideLoading();
      console.error("Error deleting event:", error);
    }
    hideLoading();
  };

  // Handler to archive the event after user confirmation
  const handleArchive = async () => {
    const confirmed = window.confirm(
      "האם אתה בטוח שברצונך להעביר את האירוע לארכיון?"
    );
    if (!confirmed) return;

    try {
      showLoading();
      const response = await fetch(`${serverUrl}/api/archive-event`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event_type: event.prefix,
          event_name: event.name,
          event_date: event.date.toLocaleDateString("en-GB"),
        }),
      });
      if (response.ok) {
        onClose();
      } else {
        hideLoading();
        console.error("Failed to archive event");
      }
    } catch (error) {
      hideLoading();
      console.error("Error archiving event:", error);
    }
    hideLoading();
  };

  return (
    <div className="popup-overlay ltr">
      <div className="popup-content ltr">
        <button className="close-btn" onClick={onClose}>
          X
        </button>
        <button
          className="refresh-btn"
          onClick={handleRefreshPopup}
          title="Refresh"
        >
          <FaSync />
        </button>
        <h2 className="popup-title">Event Details</h2>
        <p>
          <strong>Name:</strong>{" "}
          {isEditing.name && event.inProgress !== "running" ? (
            <>
              <input
                type="text"
                value={editedEvent.name}
                onChange={handleNameChange}
                className="input-editing"
              />
              <FaSave
                className="save-icon"
                onClick={() => handleSave("name")}
              />
            </>
          ) : (
            <>
              {editedEvent.name}
              {event.inProgress !== "running" && (
                <span>
                  <FaPencilAlt
                    className="edit-icon"
                    onClick={() => handleEdit("name")}
                  />
                </span>
              )}
            </>
          )}
        </p>
        <p>
          <strong>Duration:</strong>{" "}
          {isEditing.duration ? (
            <>
              <div className="date-picker-container">
                <DatePicker
                  selected={editedEvent.startTime}
                  onChange={(date) => handleDateChange("startTime", date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="dd/MM/yyyy HH:mm"
                  className="input-editing"
                  disabled={event.inProgress === "running"}
                />
                {" - "}
                <DatePicker
                  selected={editedEvent.endTime}
                  onChange={(date) => handleDateChange("endTime", date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="dd/MM/yyyy HH:mm"
                  className="input-editing"
                  minDate={editedEvent.startTime}
                  // disabled={!editedEvent.startTime}
                />
              </div>
              <FaSave
                className="save-icon"
                onClick={() => handleSave("duration")}
              />
            </>
          ) : (
            <>
              {editedEvent.startTime.toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}{" "}
              -{" "}
              {editedEvent.endTime.toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
              <span>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEdit("duration")}
                />
              </span>
            </>
          )}
        </p>
        <p>
          <strong>Type:</strong>{" "}
          {isEditing.event_type && event.inProgress !== "running" ? (
            <>
              <select
                value={editedEvent.event_type}
                onChange={handleEventTypeChange}
                className="input-editing"
              >
                <option value="swingies">Swingies</option>
                <option value="minimove">MiniMove</option>
              </select>
              <FaSave
                className="save-icon"
                onClick={() => handleSave("event_type")}
              />
            </>
          ) : (
            <>
              {editedEvent.event_type}
              {event.inProgress !== "running" && (
                <span>
                  <FaPencilAlt
                    className="edit-icon"
                    onClick={() => handleEdit("event_type")}
                  />
                </span>
              )}
            </>
          )}
        </p>
        <p>
          <strong>Stations Count:</strong>{" "}
          {isEditing.station_count ? (
            <>
              <select
                value={editedEvent.station_count}
                onChange={handleStationCountChange}
                className="input-editing"
              >
                <option value="0.35">0.35</option>
                <option value="0.7">0.7</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
              </select>
              <FaSave
                className="save-icon"
                onClick={() => handleSave("station_count")}
              />
            </>
          ) : (
            <>
              {editedEvent.station_count || 1}
              <span>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEdit("station_count")}
                />
              </span>
            </>
          )}
        </p>
        <p>
          <strong>Face Reused:</strong>{" "}
          {isEditing.reusable_heads ? (
            <>
              <select
                value={editedEvent.reusable_heads ? "Yes" : "No"}
                onChange={handleReusableHeadsChange}
                className="input-editing"
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <FaSave
                className="save-icon"
                onClick={() => handleSave("reusable_heads")}
              />
            </>
          ) : (
            <>
              {editedEvent.reusable_heads ? "Yes" : "No"}
              <span>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEdit("reusable_heads")}
                />
              </span>
            </>
          )}
        </p>
        <p>
          <strong>Keep Faces:</strong>{" "}
          {isEditing.keep_heads ? (
            <>
              <select
                value={editedEvent.keep_heads ? "Yes" : "No"}
                onChange={handleKeepHeadsChange}
                className="input-editing"
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <FaSave
                className="save-icon"
                onClick={() => handleSave("keep_heads")}
              />
            </>
          ) : (
            <>
              {editedEvent.keep_heads ? "Yes" : "No"}
              <span>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEdit("keep_heads")}
                />
              </span>
            </>
          )}
        </p>
        <p>
          <strong>Bodies:</strong> {event.png_count} images
        </p>
        <p className="event-link-container">
          <strong>Event Link:</strong>{" "}
          <a href={event.eventLink} target="_blank" rel="noopener noreferrer">
            עבור לאתר
          </a>
          <span className="qr-icon-container" ref={qrContainerRef}>
            <FaQrcode
              className="qr-icon"
              onClick={() => setShowQRCode((prev) => !prev)}
            />
            {showQRCode && (
              <QRCodePopup
                data={event.eventLink}
                image={process.env.PUBLIC_URL + `/logoTrim_${event.prefix}.png`}
                eventName={editedEvent.name}
              />
            )}
          </span>
        </p>
        <p className={getStatusStyle(mappedStatus)}>
          <strong>Status:</strong> {mappedStatus}
        </p>
        <p className={event.ready ? "status-ready" : "status-not-ready"}>
          <strong>State:</strong> {event.ready ? "Ready" : "Not Ready"}
        </p>
        {!event.ready && issues.length > 0 && (
          <div>
            <h3 className="issues-title ltr">Issues:</h3>
            <ul className="issues-list">
              {issues.map((issue, index) => (
                <li key={index} dir="ltr">
                  {issue}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="button-group">
          <button
            className="delete-btn"
            onClick={handleDelete}
            disabled={event.inProgress === "running"}
          >
            Delete Event
          </button>
          <button
            className="archive-btn"
            onClick={handleArchive}
            disabled={event.inProgress === "running"}
          >
            Archive Event
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventPopup;
