import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./MinimoveResult.css";

const MinimoveResultPage = () => {
  const navigate = useNavigate();
  const { eventName } = useParams();

  // Navigate back to event home page
  const goToNextPage = () => {
    navigate(`/${eventName}`);
  };

  return (
    <div className="minimove-result-container">
      <h1 className="minimove-result-title">תוצאה סופית - MiniMove</h1>
      <button className="minimove-result-button" onClick={goToNextPage}>
        חזרה להתחלה
      </button>
    </div>
  );
};

export default MinimoveResultPage;
