/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./MinimoveHome.css";
import { useLoading } from "../../../../Components/Common/LoadingSpinner/LoadingContext";
import { useAppContext } from "../../../../AppContext";
import { v4 as uuidv4 } from "uuid";
import {
  openDatabase,
  getLastUpdateTime,
  updateLastUpdateTime,
  cleanUpOldEntries,
  saveImageBlobToDB,
} from "../../../../IndexedDBUtils";
import homePageGif from "../../../../assets/gifs/HomePageGif.gif";
import headerImage from "../../../../assets/images/Minimove-Header.png";

const FACE_STORE_NAME = "FaceImages";
const BODY_STORE_NAME = "BodyImages";
const FACE_REFRESH_INTERVAL_SECONDS = 20;
const BODY_REFRESH_INTERVAL_SECONDS = 20;

const FACE_TTL = 1 * 1 * 0.2 * 60 * 1000; // 12 Seconds
const BODY_TTL = 1 * 1 * 2 * 60 * 1000; // 2 Minutes

const MinimoveHomePage = ({ reFace }) => {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const { eventName, setEventName, setUserId } = useAppContext();
  const params = useParams();
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [timeLeft, setTimeLeft] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const urlEventName = params.eventName;
  if (urlEventName) {
    setEventName(urlEventName);
    sessionStorage.setItem("event_name", urlEventName);
  }

  const parseDate = (dateStr) => {
    // Assuming the format is "DD/MM/YYYY HH:mm"
    const [datePart, timePart] = dateStr.split(" ");
    const [day, month, year] = datePart.split("/");
    return new Date(`${year}-${month}-${day}T${timePart}:00`);
  };

  const calculateTimeLeft = (t) => {
    const parsedDate = parseDate(t);
    const difference = new Date(parsedDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const fetchAndStoreImagesBatch = async (folderType, storeName, ttl) => {
    try {
      const lastUpdateTime = await getLastUpdateTime(storeName);
      const currentTime = new Date();
      const timeDifference = (currentTime - new Date(lastUpdateTime)) / 1000;

      if (timeDifference < ttl) {
        console.info(`Loading ${folderType} images from IndexedDB...`);
        return;
      }

      console.info(`Fetching ${folderType} images from server in batch...`);
      const response = await fetch(
        `${serverUrl}/api/images/batch?folder_path=${encodeURIComponent(
          `${urlEventName}/${folderType}`
        )}`
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch ${folderType} images batch`);
      }

      const imageBatch = await response.json();
      for (const [path, fileData] of Object.entries(imageBatch)) {
        const { base64, width, height, face_position } = fileData;
        const imageBlob = await (
          await fetch(`data:image/webp;base64,${base64}`)
        ).blob();
        await saveImageBlobToDB(
          storeName,
          path,
          imageBlob,
          width,
          height,
          face_position
        );
      }
      console.info(`Successfully updated ${folderType} images in IndexedDB.`);
      await updateLastUpdateTime(storeName);
    } catch (error) {
      console.error(`Error fetching and storing ${folderType} images:`, error);
    } finally {
      if (folderType === "bodies") {
        sessionStorage.setItem("uploads_bodies", false);
      }
    }
  };

  useEffect(() => {
    const initializeAndLoadData = async () => {
      showLoading();
      await openDatabase();
      await cleanUpOldEntries(FACE_STORE_NAME, FACE_TTL);

      try {
        const encodedEventName = encodeURIComponent(
          sessionStorage.getItem("event_name")
        );
        const response = await fetch(
          `${serverUrl}/api/get-end_event_time?event_name=${encodedEventName}`
        );
        const data = await response.json();
        if (data.endTime) {
          setEndTime(data.endTime);
          setTimeLeft(calculateTimeLeft(data.endTime));
        }
      } catch (error) {
        console.error("Error getting event end time:", error);
      }

      sessionStorage.clear();
      localStorage.clear();

      const uuid = uuidv4();
      sessionStorage.setItem("user_id", uuid);
      sessionStorage.setItem("reFace", reFace);
      setUserId(uuid);
      console.info(`user id: ${uuid}`);

      await fetchAndStoreImagesBatch(
        "faces/ready",
        FACE_STORE_NAME,
        FACE_REFRESH_INTERVAL_SECONDS
      );
      hideLoading();
      await cleanUpOldEntries(BODY_STORE_NAME, BODY_TTL);
      sessionStorage.setItem("uploads_bodies", true);
      await Promise.all([
        fetchAndStoreImagesBatch(
          "bodies",
          BODY_STORE_NAME,
          BODY_REFRESH_INTERVAL_SECONDS
        ),
      ]);
    };

    initializeAndLoadData();
  }, [params.eventName, setEventName, serverUrl]);

  useEffect(() => {
    if (endTime) {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [endTime]);

  const formatTimeLeft = () => {
    if (!timeLeft) return "00:00:00";
    const days = timeLeft.days > 0 ? `${timeLeft.days} ימים ` : "";
    return `${days}${String(timeLeft.hours).padStart(2, "0")}:${String(
      timeLeft.minutes
    ).padStart(2, "0")}:${String(timeLeft.seconds).padStart(2, "0")}`;
  };

  const goToNextPage = () => {
    showLoading();
    navigate(`/${eventName}/face-gallery`);
  };

  return (
    <div className="minimove-home-container">
      <div className="minimove-home-title">
        <img
          src={headerImage}
          alt="MiniMove"
          className="minimove-home-header-image"
        />
      </div>

      <div className="minimove-home-gif-container">
        <img src={homePageGif} alt="MiniMove Animation" />
      </div>

      <div className="minimove-home-remaining-text rtl">
        נותרו {formatTimeLeft()} לעיצוב מינימוב משלך.
      </div>

      <div className="minimove-home-start-button-container">
        <button className="minimove-home-start-button" onClick={goToNextPage}>
          התחל
        </button>
      </div>
    </div>
  );
};

export default MinimoveHomePage;
